.icon {
	--icon-size: unset;
	width: var(--icon-size);
	height: auto;
	max-width: 75vw;
	max-height: 75vh;
	flex-shrink: 0;

	/* & * {
		vector-effect: non-scaling-stroke;
	} */
}

	.icon-size--small {
			--icon-size: 1.125rem;
		}

	.icon-size--medium {
			--icon-size: 3rem;
		}

	.icon-size--large {
			--icon-size: 4.5rem;
		}

	.icon-size--extra-large {
			--icon-size: 20em;
		}

	.icon-size--max {
			--icon-size: 30em;
		}
:root {
	--accordion-header-min-height: 2.5rem;
}
.accordion {
	width: 100%;
	position: relative
}
.accordion .icon-slot {
		justify-content: center;
		width: calc(var(--min-touch-width) * 2);
		min-width: var(--min-touch-width);
		text-align: center;
	}
.selectable-bar-group .accordion .icon-slot {
			display: none;
		}
.accordion .icon-slot svg {
			max-width: 7px;
			transition: transform 0.6s var(--ease-back);
		}
.accordion__head .head-slot {
			width: 100%;
		}
.accordion__head:hover {
			cursor: pointer;
		}
.accordion__content {
		transition: var(--transition-duration) var(--ease-out-cubic);
		transition-property: transform, opacity;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		width: 100%;
	}
.accordion__content--empty {
			height: 0;
		}
.accordion ~ * {
		transition: var(--transition-duration) var(--ease-out-cubic);
		transition-property: transform, opacity;
	}
.accordion--open > .accordion__content {
			opacity: 1;
			pointer-events: all;
		}
.accordion--open ~ * {
			transform: translateY(var(--accordion-content-height));
		}
.accordion--alternative-style {
		width: calc(100% + 2 * (var(--spacing)));
		margin-left: calc(var(--spacing) * -1);
		margin-bottom: var(--spacing);
		z-index: 0;
	}
.accordion--alternative-style .radiobutton-wrapper:not(:first-child) {
			position: relative
		}
.accordion--alternative-style .radiobutton-wrapper:not(:first-child)::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: var(--color-blue);
			}
.accordion--alternative-style .accordion__head {
			background-color: var(--color-blue--medium);
			font-family: var(--font-alternative-medium);
			padding: var(--spacing);
			padding-right: 0;
		}
.accordion--alternative-style .accordion__head .icon-slot {
				text-align: right;
				padding-right: var(--spacing);
			}
.accordion--alternative-style .accordion__content {
			z-index: -1;
			transform: translateY(calc(var(--accordion-content-height) * -1));
		}
.accordion--alternative-style .accordion__content .selectable-bar {
				--selectable-bar-background-color: var(--color-blue--dark);
				--selectable-bar-headline-fontsize: var(--font-size-default);
				--selectable-bar-headline-font-family: var(--font-alternative-medium);
				transition: transform var(--transition-duration) var(--ease-out-cubic);
				transform: translateY(
					calc(
						var(--accordion-content-height) -
							(var(--accordion-content-height) * ((var(--options-index) + 1) / var(--options-length)))
					)
				);
			}
.accordion--alternative-style.accordion--open .accordion__content {
				transform: translateY(0);
			}
.accordion--alternative-style.accordion--open .accordion__content .selectable-bar {
					transform: translateY(0);
				}
.accordion--alternative-style.accordion--open ~ * {
				transform: translateY(var(--accordion-content-height));
			}
.accordion-group .accordion {
		position: relative;
		transform: translateY(0);
		transition: var(--transition-duration);
		transition-timing-function: var(--ease-out-cubic);
		transition-property: transform;
	}
.accordion-group--controlled .accordion-group__accordion-wrapper.open ~ .accordion-group__accordion-wrapper {
		transform: translateY(var(--content-height, 0));
	}
.accordion--open ~ .accordion {
		transform: translateY(var(--content-height, 0));
	}
.accordion-group__accordion-wrapper {
	transition: var(--transition-duration);
	transition-property: transform;
	transition-timing-function: var(--ease-out-cubic)
}
.accordion-group__accordion-wrapper.open ~ .accordion-group__accordion-wrapper {
			transform: translateY(var(--accordion-offset, 0));
		}
.moved-by-accordion {
	transition: var(--transition-duration);
	transition-property: transform;
	transform: translateY(var(--accordion-content-height, 0));
	transition-timing-function: var(--ease-out-cubic)
}
.moved-by-accordion.grid__bottom {
		transition-property: transform, bottom;
		transtion-duration: var(--transition-duration), 0;
		transtion-delay: 0, var(--transition-duration);
	}
.chevron-button {
	font-size: 0;
	background-color: transparent;
	appearance: none;
	outline: none;
	border: none;
	position: relative;
	z-index: 10
}
.chevron-button::before {
		z-index: -1;
		position: absolute;
		content: '';
		top: 50%;
		left: 50%;
		width: var(--min-touch-width);
		height: var(--min-touch-width);
		transform: translate3d(-50%, -50%, 0);
	}
.chevron-button:hover:not(:disabled) {
		cursor: pointer;
	}
.chevron-button:disabled {
		opacity: 0.333;
	}
.input {
	--input-transition: 0.25s var(--ease-in-out-quad);
	--border-color: currentColor;
	--input-text-color: var(--text-color);
	--input-error-color: var(--error-color);
	--input-font-family: var(--font-system);
	--input-margin-vertical: var(--spacing)
}
.input:not(.input--hidden) + .input {
		--input-margin-vertical: 0;
	}
.input--highlighted {
		--border-color: var(--error-color);
	}
.input--highlighted-hint {
			--error-color: var(--color-grey);
		}
.input--disabled {
		--border-color: transparent;
	}
.input--hidden {
		display: none !important;
	}
.input--text {
		display: grid;
		grid-template-areas: 'prepend prepend prepend' 'icon label label' 'icon tag show-password' '. error error' 'append append append';
		grid-template-columns: auto 1fr auto;
		grid-template-rows: auto auto 1fr auto auto;

		/* no margin bottom needed, as error container has the var(--spacing) as min-height */
		margin: var(--input-margin-vertical) auto 0;
		max-width: var(--input-width)
	}
.input--text::after {
			content: '';
			display: block;
			grid-area: error;
			align-self: start;
			border-bottom: 2px solid var(--border-color);
			transition: border-color var(--input-transition);
		}
.input--text .input__label {
			grid-area: label;
			font-size: var(--font-size-default);
		}
.input--text .input__error {
			display: flex;
			justify-self: end;
			align-items: center;
			grid-area: error;
			pointer-events: none;
			color: var(--error-color);
			font-size: var(--font-size-s);
			transition: opacity var(--input-transition);
			min-height: var(--spacing)
		}
.input--text .input__error:empty::before {
					content: '.';
					display: block;
					opacity: 0;
				}
.input--text .input__icon {
			grid-area: icon;
			align-self: center;
			justify-self: center;
			place-self: center;
			margin-right: var(--spacing);
		}
.input--text .input__tag {
			grid-area: tag;
			color: var(--text-color);
			font-family: var(--input-font-family);
			font-size: var(--font-size-m);
			background-color: transparent;
			border: none;
			appearance: none;
			outline: none;
			padding: 0.5em 0 0.25em;
			overflow: hidden;
			text-overflow: ellipsis;
			hyphens: none
		}
.input--text .input__tag::-webkit-calendar-picker-indicator,
			.input--text .input__tag::-webkit-inner-spin-button {
				display: none;
			}
.input--text .input__tag::-ms-clear {
				display: none;
			}
.input--text .input__tag {

			/* remove background color from autofill input */
		}
.input--text .input__tag:-webkit-autofill,
			.input--text .input__tag:-webkit-autofill:hover,
			.input--text .input__tag:-webkit-autofill:focus,
			.input--text .input__tag:-webkit-autofill:active {
				animation: autofill 0s forwards;
			}
.input--render-as-text.input--text .input__tag[disabled] {
					display: none;
				}
.input--text .input__text-representation {
			grid-area: tag;
			align-self: center;
			color: var(--text-color);
			font-family: var(--input-font-family);
			font-size: var(--font-size-m);
			padding: 0.5em 0 0.25em;
		}
.input--text .input__delete {
			--delete-opacity: 0;
			--delete-pointer-events: none;
			grid-area: tag;
			display: block;
			position: relative;
			align-self: center;
			justify-self: end;
			place-self: center end;
			width: 1rem;
			height: 1rem;
			margin-left: 1rem;
			border-radius: 100%;
			background-color: var(--color-white);
			transition: var(--input-transition);
			transition-property: opacity, background-color;
			opacity: var(--delete-opacity);
			pointer-events: var(--delete-pointer-events);
			cursor: pointer
		}
.input--text .input__delete::before,
			.input--text .input__delete::after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				background-color: var(--color-grey);
				width: 0.5625rem;
				height: 1px;
			}
.input--text .input__delete::before {
				transform: translateX(-50%) translateY(-50%) rotate(-45deg);
			}
.input--text .input__delete::after {
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
			}
.input--dirty.input--text .input__delete {
				--delete-opacity: 1;
				--delete-pointer-events: all;
			}
.input--disabled.input--text .input__delete {
				--delete-opacity: 0;
				--delete-pointer-events: none;
			}
.input__show-password {
		grid-area: show-password;
		transition: opacity var(--input-transition);
	}
.input--disabled .input__show-password {
			opacity: 0;
			pointer-events: none;
		}
.input__show-password.button {
			margin-left: 1em;
		}
.input .PhoneInputCountry {
		grid-area: icon;
	}
.input__prepend {
		grid-area: prepend;
	}
.input__append {
		grid-area: append;
	}
.input--read-only .input__delete {
			display: none;
		}
@keyframes autofill {
	100% {
		background: transparent;
		color: inherit;
	}
}
.button {
	--font-size-button: var(--font-size-m);
	--font-family-button: var(--font-alternative-medium);
	--button-height: 4rem;
	--button-transition: 0.25s var(--ease-in-out-quad);
	--hover-color: var(--color-black);
	--box-shadow: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--font-size-button);
	height: max-content;
	min-height: var(--button-height);
	color: var(--text-color);
	background-color: transparent;
	border: none;
	cursor: pointer;
	border-radius: var(--button-height);
	padding: var(--spacing--small) calc(var(--spacing) * 2);
	outline: none;
	margin: var(--spacing) auto;
	transition: var(--button-transition);
	transition-property: box-shadow, transform, opacity;
	position: relative;
	z-index: 0;
	box-shadow: var(--box-shadow)
}
.button[disabled] {
		pointer-events: none;
		--box-shadow: none
	}
.button[disabled]::after {
			opacity: 0.7;
		}
.button::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
		background-color: inherit;
		opacity: 0;
		transition: opacity var(--button-transition);
		border-radius: var(--button-height);
		pointer-events: none;
	}
.button::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 0;
		background-color: var(--hover-color);
		opacity: 0;
		transition: opacity var(--button-transition);
		border-radius: var(--button-height);
		pointer-events: none;
	}
.button > span {
		position: relative;
		z-index: 10;
		pointer-events: none;
		font-family: var(--font-family-button);
	}
.button--primary {
		background: var(--color-cyan);
		--box-shadow: var(--box-shadow-bottom--4-steps);
		transform: translateY(0)
	}
.button--primary:hover {
			transform: translateY(-2px);
			--box-shadow: var(--box-shadow-bottom--5-steps);
		}
.button--primary:active {
			transform: translateY(1px);
			--box-shadow: var(--box-shadow-bottom--3-steps);
		}
.button--grey {
		background: var(--color-grey);
	}
.button--secondary {
		--box-shadow: 0 0 0 2px var(--text-color) inset
	}
.button--secondary:hover::before {
				opacity: 0.15;
			}
.button--secondary:active::before {
				opacity: 0.3;
			}
.button--secondary[disabled] {
			opacity:0.3;
			--box-shadow: 0 0 0 2px var(--text-color) inset;
		}
.button a,
	.button a:visited,
	.button a:focus {
		text-decoration: none;
		color: var(--button-text-color);
		font-family: var(--font-family-button)
	}
.button a::before, .button a:visited::before, .button a:focus::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
.button--small {
		--button-height: 2rem;
		--spacing: var(--spacing--small);
		--font-size-button: var(--font-size-s);
	}
.button--text {
		--font-family-button: var(--font-alternative-light);
		transition-property: transform, opacity;
		--spacing: 0;
	}
.button--link {
		--font-family-button: inherit;
		--font-size-button: inherit;
		--button-height: auto;
		text-decoration: underline;
		transition-property: transform, opacity;
		display: inline;
		--spacing: 0;
		margin: 0;
	}
.input--range-slider {
		display: grid;
		grid-template-areas: 'label' 'indicator' 'tag' 'scale';
		margin: var(--spacing) auto;
		max-width: calc(var(--input-width) + var(--spacing) * 4);
		padding: 0 calc(var(--spacing) * 2);
	}
.input--range-slider .input__label {
			grid-area: label;
		}
.input {
	overflow: visible;
}
.range-slider {
	--thumb-color: var(--color-cyan);
	--track-color: rgba(255, 255, 255, 0.2);
	--current-track-color: var(--color-cyan);
	--track-height: 0.125rem;
	--track-dot-height: var(--track-height);
	--thumb-transition: 0.25s var(--ease-in-out-quad);
	--thumb-height: 1.875rem;
	--scale-color: var(--color-white);
	--indicator-color: var(--color-cyan);
	--thumb-box-shadow: var(--box-shadow-bottom--4-steps);
	position: relative;
	z-index: 0;
	overflow: hidden;
}
.range-slider--blue {
		--thumb-color: var(--color-blue);
		--current-track-color: var(--color-blue);
		--indicator-color: var(--color-blue);
	}
.range-slider--white {
		--thumb-color: var(--color-white);
		--current-track-color: var(--color-white);
		--indicator-color: var(--color-white);
	}
.range-slider--dark-grey {
		--thumb-color: var(--color-grey--dark);
		--current-track-color: var(--color-grey--dark);
		--indicator-color: var(--color-grey--dark);
	}
.range-slider--disabled {
		pointer-events: none;
		opacity: 0.3;
		--thumb-box-shadow: none;
	}
.range-slider--read-only {
		--thumb-box-shadow: none;
		--thumb-height: 1rem;
		pointer-events: none;
	}
.range-slider__tag {
		position: relative;
		width: 100%;
		height: var(--thumb-height);
		grid-area: tag;
		background-color: transparent;
		appearance: none;
		outline: none;
		z-index: 10;
		overflow: visible
	}
.range-slider__tag:target,
		.range-slider__tag:focus,
		.range-slider__tag:active {
			border: none;
			outline: none;
		}
.range-slider__tag::-webkit-slider-runnable-track {
			width: 100%;
			height: var(--thumb-height);
			cursor: pointer;
			overflow: visible;
		}
.range-slider__tag::-moz-range-track {
			width: 100%;
			height: var(--thumb-height);
			cursor: pointer;
			background-color: transparent;
		}
.range-slider__tag::-webkit-slider-thumb {
			overflow: visible;
			position: relative;
			appearance: none;
			width: var(--thumb-height);
			height: var(--thumb-height);
			background-color: var(--thumb-color);
			cursor: pointer;
			border-radius: 100%;
			border: none;
			-webkit-transition: var(--thumb-transition);
			transition: var(--thumb-transition);
			-webkit-transition-property: transform, box-shadow;
			transition-property: transform, box-shadow;
			box-shadow: var(--thumb-box-shadow);
			transform: translateX(-50%) translateX(calc(var(--current-range, 0) * 100%)) translateY(0)
		}
.range-slider__tag::-webkit-slider-thumb:hover {
				--thumb-box-shadow: var(--box-shadow-bottom--5-steps);
				transform: translateX(-50%) translateX(calc(var(--current-range, 0) * 100%)) translateY(-2px);
			}
.range-slider__tag::-webkit-slider-thumb:active {
				--thumb-box-shadow: var(--box-shadow-bottom--3-steps);
				transform: translateX(-50%) translateX(calc(var(--current-range, 0) * 100%)) translateY(1px);
			}
.range-slider__tag::-webkit-slider-thumb {

			/**
			 * MS Edge cuts off the thumb when it is running out of the tags bounding box.
			 * to prevent his behaviour the thumb is not placed on the dots
			 */
		}
@supports (-ms-ime-align: auto) {

		.range-slider__tag::-webkit-slider-thumb {
				transform: translateY(0)
		}

				.range-slider__tag::-webkit-slider-thumb:hover {
					transform: translateY(-2px);
				}

				.range-slider__tag::-webkit-slider-thumb:active {
					transform: translateY(1px);
				}
			}
.range-slider__tag::-moz-range-thumb {
			position: relative;
			appearance: none;
			width: var(--thumb-height);
			height: var(--thumb-height);
			background-color: var(--thumb-color);
			cursor: pointer;
			border-radius: 100%;
			border: none;
			-moz-transition: var(--thumb-transition);
			transition: var(--thumb-transition);
			-moz-transition-property: transform, box-shadow;
			transition-property: transform, box-shadow;
			box-shadow: var(--thumb-box-shadow);
			transform: translateX(-50%) translateX(calc(var(--current-range, 0) * 100%)) translateY(0)
		}
.range-slider__tag::-moz-range-thumb:hover {
				--thumb-box-shadow: var(--box-shadow-bottom--5-steps);
				transform: translateX(-50%) translateX(calc(var(--current-range, 0) * 100%)) translateY(-2px);
			}
.range-slider__tag::-moz-range-thumb:active {
				--thumb-box-shadow: var(--box-shadow-bottom--3-steps);
				transform: translateX(-50%) translateX(calc(var(--current-range, 0) * 100%)) translateY(1px);
			}
.range-slider__current-range {
		grid-area: tag;
		align-self: center;
		position: relative;
		width: 100%;
		height: var(--track-height);
		border-radius: var(--track-height);
		pointer-events: none
	}
.range-slider__current-range::before {
			content: '';
			position: absolute;
			height: 100%;
			width: 100%;
			background-color: var(--track-color);
		}
.range-slider__current-range::after {
			content: '';
			position: absolute;
			height: 100%;
			width: calc(100% * var(--current-range, 0));
			background: var(--current-track-color);
		}
.range-slider__indicator {
		grid-area: indicator;
		position: relative;
		color: var(--indicator-color);
		font-size: var(--font-size-l);
		font-family: var(--font-alternative-medium);
		text-align: center;
		transform: translateX(-50%) translateX(calc(100% * var(--current-range, 0)));
	}
.range-slider__dots {
		grid-area: tag;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
.range-slider__dot {
		display: block;
		position: relative;
		width: var(--track-dot-height);
		height: var(--track-dot-height);
		border-radius: 100%;
		background-color: var(--color-white);
	}
.range-slider__scale {
		grid-area: scale;
		display: flex;
		justify-content: space-between;
		font-family: var(--font-alternative-light);
		color: var(--scale-color)
	}
.range-slider__scale span:first-child {
				transform: translateX(-50%);
			}
.range-slider__scale span:last-child {
				transform: translateX(50%);
			}
.error {
	--error-transition: 0.25s var(--ease-in-out-quad);
	display: grid;
	max-width: var(--input-width);
	color: var(--error-color);
	transition: opacity var(--error-transition);
	grid-template-columns: min-content 1fr;
	grid-template-areas: 'icon message';
	align-items: center;
	opacity: 0;
	margin-left:auto;
	margin-right:auto;
}
.error--visible {
		opacity: 1;
	}
.error__messages {
	
		grid-area: message;
		font-weight: var(--font-system-bold);
	}
.error__icon {
		width: 3.5rem;
		height: 3.5rem;
		grid-area: icon;
		align-self: center;
		justify-self: end;
	}
.external-link {
	color: var(--color-cyan);
}
.checkbox {
	--checkmark-transition: 0.25s var(--ease-in-out-quad);
	--checkmark-visibility: 40;
	--checkbox-size: 1.5rem;
	display: grid;
	grid-template-areas: 'prepend prepend' 'tag-left tag-right' 'error-left error-right' 'append append';
	grid-template-columns: auto 1fr;
	grid-template-rows: repeat(4, auto);
	grid-column-gap: var(--spacing);
	column-gap: var(--spacing);
	justify-items: start;
}
.checkbox--position-right {
		grid-template-columns: 1fr auto;
	}
.checkbox--large {
		--checkbox-size: 2.5rem;
	}
.checkbox > *:first-child:not(.input__tag) {
		grid-area: prepend;
		align-self: start;
	}
.checkbox > *:last-child:not(.input__error) {
		grid-area: append;
	}
.checkbox label {
		cursor: pointer;
	}
.checkbox .input__tag {
			position: absolute;
			z-index: -1;
			opacity: 0
		}
.checkbox .input__tag:focus-visible + label {
					outline-color: currentColor;
					outline-width: 2px;
					outline-style: dashed;
					outline-offset: 2px;
				}
.checkbox .input__tag:checked + label {
					--checkmark-visibility: 0;
				}
.checkbox .input__pseudo-checkbox {
			display: block;
			width: var(--checkbox-size);
			height: var(--checkbox-size);
			align-self: start;
			margin: var(--spacing--small) 0;
		}
.checkbox--position-left.checkbox .input__pseudo-checkbox {
				grid-area: tag-left;
			}
.checkbox--position-right.checkbox .input__pseudo-checkbox {
				grid-area: tag-right;
			}
.checkbox .input__label {
			padding: 0;
			align-self: center;
			justify-self: start;
			max-width: 100%;
		}
.checkbox--position-left.checkbox .input__label {
				grid-area: tag-right;
			}
.checkbox--position-right.checkbox .input__label {
				grid-area: tag-left;
			}
.checkbox .input__error {
			color: var(--error-color);
			font-size: var(--font-size-s);
			transition: opacity var(--input-transition)
		}
.checkbox .input__error:empty::before {
					content: '.';
					display: block;
					opacity: 0;
				}
.checkbox--position-left.checkbox .input__error {
				grid-area: error-right;
			}
.checkbox--position-right.checkbox .input__error {
				grid-area: error-left;
			}
.checkbox__svg {
		--checkbox-stroke-width: 1px;
		/* TODO: use dynamically calculated path length here */
		--checkbox-path-length: 40;
	}
.checkbox__svg polyline {
			stroke-dashoffset: var(--checkmark-visibility);
			stroke-dasharray: var(--checkbox-path-length);
			transition: stroke-dashoffset var(--checkmark-transition);
		}
.payout-model-list {
	--payout-list-transition-duration: 0.25s;
	--payout-list-transition: var(--payout-list-transition-duration) var(--ease-in-out-quad);
	width: calc(100% + var(--spacing) * 2);
	margin-left: calc(var(--spacing) * -1);
}
.payout-model-list__item {
		--radiobutton-background-opacity: 0;
		--sub-label-color: var(--color-cyan);
		border-top: 1px solid var(--color-blue);
		transform: translateY(var(--item-translate-y, 0));
		transition: transform var(--payout-list-transition)
		/**
         * this helps to hide the description behind the next item 
         * when opening and closing the items  
         */
		/* backdrop-filter: blur(20px); */
	}
.payout-model-list__item[data-checked='true'] {
			--radiobutton-background-opacity: 1;
			--sub-label-color: var(--color-blue--dark);
		}
.payout-model-list__item[data-checked='true'] ~ .payout-model-list__item {
				--item-translate-y: var(--description-height);
			}
.payout-model-list__item--disabled {
			border-top: 1px solid var(--color-cyan--transparent);
		}
.payout-model-list__item:last-child {
			margin-bottom: calc(var(--description-height, 0));
		}
.payout-model-list__sub-label {
		display: block;
		font-size: var(--font-size-s);
		color: var(--sub-label-color);
	}
.payout-model-list__radiobutton {
		--radiobutton-transition: var(--payout-list-transition);
		position: relative;
		z-index: 0;
		font-size: var(--font-size-m);
		padding: var(--spacing);
		cursor: pointer
	}
.payout-model-list__radiobutton::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			/* background-image: linear-gradient(o left, var(--color-blue), var(--color-cyan)); */
			background: var(--color-cyan);
			box-shadow: var(--box-shadow-bottom--4-steps);
			transition: var(--payout-list-transition) var(--payout-list-transition-duration);
			transition-property: opacity, box-shadow;
			z-index: -1;
			height: calc(100% + var(--description-height));
			opacity: 0;
		}
.payout-model-list__radiobutton.radiobutton--checked::after {
				box-shadow: var(--box-shadow-bottom--4-steps);
				opacity: var(--radiobutton-background-opacity);
			}
.payout-model-list__item-description {
		width: 100%;
		position: absolute;
		pointer-events: none;
		opacity: 0;
		overflow: hidden;
		padding: 0 var(--spacing) var(--spacing);
		transition: opacity var(--payout-list-transition);
		transform: translateY(0);
	}
.payout-model-list__item-description--active {
			transition: opacity var(--payout-list-transition) var(--payout-list-transition-duration);
			opacity: 1;
			pointer-events: all;
		}
.payoutmodel-shares {
	position: relative;
	width: 33%;
	margin-left: 50%;
	transform: translateX(-50%);
	margin-top: 100px;
	margin-bottom: 150px;
	--share-bg-color: pink;
	--color: #ffffff;

	--share-z-1: 0;
	--share-z-2: 20;
	--share-z-3: 50;
	--share-z-4: 50;
}
.payoutmodel-shares--show-sum {
		--sum-display: block;
		--percentage-font-size: var(--font-size-xs);
	}
.payoutmodel-shares__share {
		width: 100%;
		padding-bottom: 100%;
		position: absolute;
		text-align: center;
		top: 0;
	}
.payoutmodel-shares__share--hidden {
			opacity: 0;
		}
.payoutmodel-shares__share .amount {
			width: 100%;
			height: 100%;
			position: absolute;
			color: var(--share-color);
		}
.payoutmodel-shares__share .amount .description__label {
				max-width: 80px;
			}
.payoutmodel-shares__share .amount .description__sum {
				display: var(--sum-display, none);
			}
.payoutmodel-shares__share .amount__percentage {
				font-size: var(--percentage-font-size, var(--font-size-m));
			}
.payoutmodel-shares__share .amount .amount__percentage--mobile {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				visibility: hidden;
			}
.payoutmodel-shares__share .amount--alt .amount__percentage--desktop {
					visibility: hidden;
				}
.payoutmodel-shares__share .amount--alt .amount__percentage--mobile {
					visibility: visible;
				}
.payoutmodel-shares__share .amount .amount__description--mobile {
				display: none;
			}
.payoutmodel-shares__share .amount--alt .amount__description--desktop {
					visibility: hidden;
				}
.payoutmodel-shares__share .amount--alt .amount__description--mobile {
					display: block;
					--desc-transform: translate3d(0, 0, 0);
					position: absolute;
					width: 100%;
					height: 100%;
					transform: var(--desc-transform);
					color: var(--share-bg-color);
					line-height: 1;
				}
.payoutmodel-shares__share .circle {
			--offset: 0;
			--share-position-transition: translate3d(0, 0, 0);
			width: 100%;
			padding-bottom: 100%;
			transform-origin: center;
			--scale: var(--share);
			transform: var(--share-position-transition) scale(var(--scale));
			border-radius: var(--border-radius);
			background-color: var(--share-bg-color);
			box-shadow: var(--box-shadow-bottom--4-steps);
			transition: var(--transition-duration) var(--ease-out-back);
		}
.payoutmodel-shares__share .amount,
		.payoutmodel-shares__share .circle {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			flex-direction: column;
		}
.payoutmodel-shares__share:first-child {
			position: relative;
			right: 100%;
			z-index: var(--share-z-1);
		}
.payoutmodel-shares__share:first-child .circle {
				transform-origin: right center;
			}
.payoutmodel-shares__share:first-child .amount {
				transform: translate3d(calc((100% - (100% * var(--share-ratio-0))) / 2), 0, 0);
			}
.payoutmodel-shares__share:first-child .amount--alt .amount__description--mobile {
						top: 50%;
						right: 50%;
						text-align: right;
						--desc-transform: translateX(calc(-100% * var(--share-ratio-0) / 2));
					}
.payoutmodel-shares__share:first-child .amount--alt .amount__description--mobile .description__label {
							margin-left: auto;
						}
.payoutmodel-shares__share:nth-child(2) {
			margin-left: -10px;
			z-index: var(--share-z-2);
		}
.payoutmodel-shares__share:nth-child(2) .circle {
				transform-origin: bottom left;
				--share-position-transition: translate3d(0, 0, 0);
			}
.payoutmodel-shares__share:nth-child(2) .amount {
				transform: translate3d(
					calc((-100% + (100% * var(--share-ratio-1))) / 2),
					calc((100% - (100% * var(--share-ratio-1))) / 2),
					0
				);
			}
.payoutmodel-shares__share:nth-child(2) .amount--alt .amount__description--mobile {
						text-align: left;
						--desc-transform: translateX(calc(((100% * var(--share-ratio-1))) / 2));
					}
.payoutmodel-shares__share:nth-child(2) {
			left: 100%;
			top: -50%;
			transform: translateX(calc(-100% * (1 - var(--share-ratio-2))));
}
.payoutmodel-shares__share:nth-child(3) {
			margin-left: -5px;
			top: -50%;
			z-index: var(--share-z-3);
		}
.payoutmodel-shares__share:nth-child(3) .circle {
				transform-origin: bottom left;
			}
.payoutmodel-shares__share:nth-child(3) .amount {
				z-index: 999;
				transform: translate3d(
					calc((-100% + (100% * var(--share-ratio-2))) / 2),
					calc((100% - (100% * var(--share-ratio-2))) / 2),
					0
				);
			}
.payoutmodel-shares__share:nth-child(3) .amount--alt .amount__description--mobile {
						text-align: left;
						top: 0%;
						left: 50%;
						--desc-transform: translateY(calc((100% - (100% * var(--share-ratio-2))) / 2));
					}
.payoutmodel-shares__share:nth-child(3) .amount--alt .amount__description--mobile .description__inner {
							transform-origin: left center;
							transform: rotate(-90deg) translateX(calc((100% * var(--share-ratio-2)) / 2));
						}
.payoutmodel-shares__share:nth-child(4) {
			/* transform: translate3d(-50%, 50%, 0); */
			top: 50%;
			margin-left: -5px;
			z-index: var(--share-z-4);
		}
.payoutmodel-shares__share:nth-child(4) .circle {
				transform-origin: top left;
			}
.payoutmodel-shares__share:nth-child(4) .amount {
				transform: translate3d(
					calc((-100% + (100% * var(--share-ratio-3))) / 2),
					calc((-100% + (100% * var(--share-ratio-3))) / 2),
					0
				);
			}
.payoutmodel-shares__share:nth-child(4) .amount--alt .amount__description--mobile {
						text-align: left;
						top: 50%;
						left: 50%;
						--desc-transform: translateY(calc(100% * var(--share-ratio-3) / 2));
					}
.payoutmodel-shares__share:nth-child(4) .amount--alt .amount__description--mobile .description__inner {
							transform: rotate(90deg);
							transform-origin: left center;
							/* transform: translateY(calc((100% * var(--share-ratio-3)) / 2)); */
						}
.payout-input-progress {
	width: 100%;
	max-width:30rem;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:var(--spacing);
	display: flex;
}
.payout-input-progress__step {
		flex: 1;
		display: flex;
		align-items: center;
		opacity: 0.333
	}
.payout-input-progress__step::before {
			content: '';
			display: block;
			--diameter: 9px;
			height: var(--diameter);
			width: var(--diameter);
			background: var(--color-cyan);
			border-radius: 100%;
			
		}
.payout-input-progress__step::after {
			content: '';
			display: block;
			border-top: 1px solid var(--color-cyan);
			flex: 1;
			margin-right:2px;
		}
.payout-input-progress__step:last-child {
			max-width: 9px
		}
.payout-input-progress__step:last-child::after {
				display: none;
			}
.payout-input-progress__step:first-child {
			display: none;
		}
.payout-input-progress__step--active {
			opacity: 1;
		}
div.payout-model-form {
	max-width: 480px;
	margin: 0 auto;
}
div.payout-model-form form {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
	}
div.payout-model-form form .fields {
			display: contents;
		}
div.payout-model-form form .select-with-background {
			margin-bottom: 0;
		}
div.payout-model-form form > * {
			grid-column: 1/2;
		}
div.payout-model-form form .input,
		div.payout-model-form form .select-input {
			width: 100%;
		}
div.payout-model-form form .info-content {
			margin-top: var(--spacing);
		}
div.payout-model-form form .info-content--social-insurance {
				grid-row: 3/4;
			}
div.payout-model-form form .info-content .info-box {
				display: flex;
				flex-direction: column;
				align-items: center;
				background-color: #ffffff;
				box-shadow: var(--box-shadow--4-steps);
				border-radius: 2rem;
				padding: var(--padding);
			}
div.payout-model-form form .info-content .info-box button {
					color: var(--color-blue);
				}
div.payout-model-form .invalid-input-error {
		text-align:right;
		margin-top:calc(var(--spacing) * -1);
	}
div.payout-model-form .subheadline {
		width: calc(100% + var(--spacing--large) * 2);
		justify-self: center;
		max-width:100vw;
		font-weight: bold;
		color: var(--color-cyan);
		border-top: 2px solid var(--color-cyan);
		padding: var(--spacing) var(--spacing--large) 0 var(--spacing--large);
		padding-bottom: 0;
		margin-top: var(--spacing--large);
	}
div.payout-model-form .subheadline--taxnumber,
		div.payout-model-form .subheadline--social-insurance,
		div.payout-model-form .subheadline--health-insurance,
		div.payout-model-form .subheadline--bank-account,
		div.payout-model-form .subheadline--personal {
			margin-top: 0;
			grid-row: 1/2;
		}
div.payout-model-form .subheadline--pflegeversicherung {
			grid-row: 4/5;
		}
div.payout-model-form .subheadline--taxclass {
			grid-row: 5/6;
		}
div.payout-model-form .subheadline--address {
			grid-row: 7/8;
			margin-bottom: var(--spacing);
		}
div.payout-model-form .subheadline--contact {
			grid-row: 12/13;
		}
div.payout-model-form .input.invalid {
		--border-color:var(--input-error-color);
	}
div.payout-model-form .bank-name {
		opacity: 0.333;
	}
div.payout-model-form .payout-form-summary {
		margin: 0 auto;
	}
div.payout-model-form .payout-form-summary .subheadline {
			border-top: 0;
			margin-left: calc(var(--spacing--large) * -1);
			margin-bottom: var(--spacing);
			margin-top: var(--spacing--small);
		}
div.payout-model-form .payout-form-summary .row {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-column-gap: var(--spacing);
		}
div.payout-model-form .payout-form-summary__section {
			border-top: 1px solid var(--color-cyan)
		}
div.payout-model-form .payout-form-summary__section:not(:first-child) {
				margin-top: var(--spacing);
			}
div.payout-model-form .payout-form-summary__values {
			margin: 0 auto;
		}
div.payout-model-form .payout-form-summary button {
			margin-top: 1.8rem;
		}
div.payout-model-form .payout-input-intro__list {
		font-size: var(--font-size-m);
		font-family: var(--font-alternative-light);
	}
div.payout-model-form .payout-input-intro__list ul {
			list-style-type: none;
			margin: 0;
			padding-left: 0;
		}
div.payout-model-form .payout-input-intro__list ul > li::before {
			content: '—';
			margin-right: 15px;
		}
.payout-form-summary {
	margin: 0 auto;
}
.payout-form-summary .subheadline {
		border-top: 0;
		margin-left: calc(var(--spacing--large) * -1);
		margin-bottom: var(--spacing);
		margin-top: var(--spacing--small);
	}
.payout-form-summary .row {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-column-gap: var(--spacing);
	}
.payout-form-summary__section {
		border-top: 1px solid var(--color-cyan)
	}
.payout-form-summary__section:not(:first-child) {
			margin-top: var(--spacing);
		}
.payout-form-summary__values {
		margin: 0 auto;
	}
.payout-form-summary button {
		margin-top: 1.8rem;
	}
.radiobutton {
	--radiobutton-transition: 0.25s var(--ease-in-out-quad);
	--radiobutton-visibility: 0;
	display: flex;
	align-items: center;
}
.radiobutton--checked {
		--radiobutton-visibility: 1;
	}
.radiobutton--disabled {
		opacity: 0.2;
		pointer-events: none;
	}
.radiobutton:hover:not(.radiobutton--checked) {
		--radiobutton-visibility: 0.5;
	}
.radiobutton label {
		cursor: pointer;
		font-family: var(--font-alternative-light);
		font-weight: normal;
	}
.radiobutton .input__tag {
			display: none
		}
.radiobutton .input__tag:checked ~ label {
				--radiobutton-visibility: 1;
			}
.radiobutton .input__pseudo-radiobutton {
			display: block;
			width: 1.5em;
			height: 1.5em;
		}
.radiobutton .input__label {
			padding: 0 var(--spacing) 0 1em;
			flex: 1;
		}
.radiobutton__svg {
		--radiobutton-stroke-width: 1px;
		--radiobutton-path-length: 25;
	}
.radiobutton__svg .radiobutton__inner-circle {
			transform: scale(var(--radiobutton-visibility));
			transform-origin: 50% 50%;
			transition: transform var(--radiobutton-transition);
		}
.radiobutton-group--switchmode {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
		position: relative
	}
.radiobutton-group--switchmode::after {
			background-color: #000000;
			opacity: 0.333;
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: -1;
			top: 0;
			left: 0;
			border-radius: 2rem;
		}
.radiobutton-group--switchmode .input__pseudo-radiobutton {
				display: none;
			}
.chart--bar {
		/* width: 200px; */
		/* height: 70vh; */
	}
.bar-chart {
	--chart-font-family: var(--font-alternative-medium);
	--bar-chart-color: var(--color-cyan);
	--bar-chart-label-color: var(--color-white);
	width: 100%;
	position: relative;
	z-index: 0;
}
.bar-chart__chart-wrapper {
		display: grid;
		grid-template-areas: 'pan-left chart pan-right' 'legend legend legend';
		grid-template-columns: min-content minmax(0, 100%) min-content;
		grid-template-rows: 1fr min-content;
	}
.bar-chart__pan {
		position: relative;
		padding: calc(var(--spacing) * 2) var(--spacing) 0;
		height: 100%;
		width: min-content;
		margin: 0;
		z-index: 10;
	}
.bar-chart__pan--left {
			grid-area: pan-left;
			justify-self: start;
			transform: translateX(calc(var(--spacing) * -1));
		}
.bar-chart__pan--right {
			grid-area: pan-right;
			justify-self: end;
			transform: translateX(var(--spacing));
		}
.bar-chart__pan[disabled] {
			opacity: 0.3;
		}
.bar-chart__chart {
		grid-column: 1 / -1;
		grid-row: 1 / 2;
		display: flex;
		align-content: center;
		justify-content: center;
		place-content: center
	}
.bar-chart__chart > div {
			mask-image: linear-gradient(
				to right,
				transparent 0%,
				#000000 var(--spacing),
				#000000 calc(100% - var(--spacing)),
				transparent 100%
			)
		}
.bar-chart__chart > div .VictoryContainer {
				position: absolute !important;
			}
.bar-chart__legend {
		margin: var(--spacing) 0;
		grid-area: legend;
		display: grid;
		grid-row-gap: var(--spacing--small);
	}
.bar-chart__bar {
		cursor: pointer;
	}
.bar-chart__label {
		cursor: pointer;
	}
.bar-chart__label--x {
			text-transform: uppercase;
		}
/**
	 * MS Edge does not support clip-path, so the stop-opacity must be overwritten,
	 * to make it look like it cut off
	 */
.bar-chart #stack2color stop:last-child {
			stop-opacity: 0 !important
		}
@supports (clip-path: none) {
		.bar-chart #stack2color stop:last-child {
				stop-opacity: 0.4 !important
		}
			}
.legend-item {
	--item-color: var(--legend-item-color, var(--color-white));
	color: var(--item-color);
	display: flex;
	align-items: center
}
.legend-item::before {
		content: '';
		width: 4rem;
		height: 0.25rem;
		border-radius: 0.25rem;
		margin-right: 1rem;
		background-color: var(--item-color);
		mask: linear-gradient(to right, transparent 0%, #000000 100%);
		flex-shrink: 0;
	}
.select-input {
	--active-color: var(--color-white);
	display: grid;
	grid-template-areas:
		'top top top'
		'left center right';
	grid-template-columns: var(--min-touch-width) 1fr var(--min-touch-width);
	align-items: center;
}
.select-input__select-field {
		opacity: 0;
		font-size: 0;
		grid-area: center;
	}
.select-input__label {
		grid-area: top;
		margin-bottom: var(--spacing--small);
	}
.select-input__value {
		position: relative;
		grid-area: center;
		appearance: none;
		background-color: transparent;
		outline: none;
		border: none;
		color: var(--active-color);
		text-align: center;
		z-index: 20;
		cursor: pointer;
	}
.select-input__button {
		position: relative;
		z-index: 10;
	}
.select-input__button--previous {
			grid-area: left;
		}
.select-input__button--next {
			grid-area: right;
		}
.select-input .select-options-modal__option {
			padding: var(--spacing);
			border-bottom: 1px solid var(--color-blue);
			position: relative;
			z-index: 0;
		}
.select-input .select-options-modal__radiobutton {
			flex-wrap: wrap;
			cursor: pointer;
			--option-background-opacity: 0
		}
.select-input .select-options-modal__radiobutton.radiobutton--checked {
				--option-background-opacity: 1;
			}
.select-input .select-options-modal__radiobutton::before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				opacity: var(--option-background-opacity);
				background-color: var(--color-cyan);
				transition: 0.25s var(--ease-in-out-quad);
				transition-property: opacity, box-shadow;
				z-index: -1;
				box-shadow: var(--box-shadow-bottom--3-steps);
			}
.select-input .select-options-modal__radiobutton::after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
.select-input .select-options-modal__radiobutton .input__label {
				font-family: var(--font-alternative-light);
				font-size: var(--font-size-l);
				color: var(--color-white);
			}
.select-input .select-options-modal__radiobutton .input__pseudo-radiobutton {
				font-size: var(--font-size-m);
			}
div.select-with-background,
.form div.select-with-background {
	margin-top:var(--spacing);
	margin-bottom:var(--spacing--s);
	
	cursor: pointer;
}
div.select-with-background div.select-input__label, .form div.select-with-background div.select-input__label {
		font-size: var(--font-size-default);
	}
div.select-with-background select, .form div.select-with-background select {
		grid-column: 1 / -1;
		height: 100%;
	}
div.select-with-background select option, .form div.select-with-background select option {
			font-size:12px;
		}
div.select-with-background .select-input__value,  .form div.select-with-background .select-input__value  {
		pointer-events: none;
		grid-column: 1 / -1;
		background-color: var(--color-black-transparent);
		font-size: var(--font-size-default);
		padding: var(--spacing) var(--spacing);
		text-align: left;
	}
div.select-with-background .select-input__value svg, .form div.select-with-background .select-input__value svg {
			z-index: 100;
			position: absolute;
			right: var(--spacing);
			top: 50%;
			transform: translate(0, -50%) rotate(90deg);
			--svg-color: var(--color-cyan) !important;
			cursor: pointer;
		}
.submenu-bar {
	--submenu-bar-headline-fontsize: var(--font-size-m);
	--submenu-bar-headline-font-family: var(--font-alternative-light);
	--submenu-bar-subheadline-fontsize: var(--font-size-s);
	--submenu-bar-icon-size: 2.5rem;
	--submenu-bar-go-to-icon-size: 0.45rem;
	--submenu-bar-headline-color: var(--color-white);
	--submenu-bar-subheadline-color: var(--color-cyan);
	display: grid;
	position: relative;
	grid-template-areas: 'icon headline go-to-icon' 'icon subheadline go-to-icon';
	grid-template-columns: auto minmax(0, 100%) auto;
	grid-template-rows: min-content min-content;
	align-items: center;
	z-index: 0;
	padding: var(--spacing);
	background-color: var(--submenu-bar-background-color);
	border-bottom: 1px solid var(--color-blue);
	cursor: pointer;
}
.submenu-bar--unselectable {
		cursor: default;
	}
.submenu-bar:first-child {
		border-top: 1px solid var(--color-blue);
	}
.submenu-bar-group {
		width: calc(100% + var(--spacing) * 2);
		margin-left: calc(var(--spacing) * -1);
	}
.submenu-bar__icon {
		grid-area: icon;
		--icon-size: var(--submenu-bar-icon-size);
		margin-right: var(--spacing);
		align-self: start;
	}
.submenu-bar__go-to-icon {
		grid-area: go-to-icon;
		--icon-size: var(--submenu-bar-go-to-icon-size);
		margin-left: var(--spacing);
	}
.submenu-bar__headline {
		grid-area: headline;
		color: var(--submenu-bar-headline-color);
		font-family: var(--submenu-bar-headline-font-family);
		font-size: var(--submenu-bar-headline-fontsize);
		padding: 0;
	}
.submenu-bar__subheadline {
		grid-area: subheadline;
		color: var(--submenu-bar-subheadline-color);
		font-size: var(--submenu-bar-subheadline-fontsize);
	}
.sunburst-chart__wrapper {
		overflow: visible;
	}
.sunburst-chart__data-slice,
	.sunburst-chart__ring-slice {
		transition: opacity 0.25s var(--ease-in-out-quad);
	}
.big-color-figure {
	--active-color: #ffffff;
	position: relative;
	padding: 4px 0;
	text-align: right;
	white-space: nowrap;
}
.big-color-figure__figure {
		color: var(--active-color);
	}
.big-color-figure::after {
		background-color: var(--active-color);
		content: '';
		position: absolute;
		height: 4px;
		width: 100%;
		bottom: 0;
		left: 0;
		border-radius: 0 1rem 1rem 0;
	}
.snap-slider {
	overflow: hidden;
	position: relative;
	width: calc(100% + 2 * var(--spacing));
	margin-left: calc(var(--spacing) * -1);
	display: grid;
	grid-template-columns: var(--min-touch-width) 1fr var(--min-touch-width);
	grid-template-areas: 'left center right'
}
.snap-slider ::-webkit-scrollbar {
		display: none;
	}
.snap-slider__wrapper {
		grid-column: 1 / -1;
		grid-row: 1;
		justify-self: center;
		position: relative;
		display: flex;
		width: auto;
		max-width: 100%;
		overflow-x: auto;
		/* scroll-snap-type: x mandatory; */
		justify-content: left;
		mask-image: linear-gradient(
			to right,
			transparent 0%,
			transparent var(--spacing),
			#000000 var(--min-touch-width),
			#000000 calc(100% - var(--min-touch-width)),
			transparent calc(100% - var(--spacing)),
			transparent 100%
		);
		/* padding-bottom needed so buttons shadow is not cut off */
		padding-bottom: var(--spacing);
	}
.snap-slider__snappoint {
		/* scroll-snap-align: center; */
		min-width: max-content;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 var(--spacing--small)
	}
.snap-slider__snappoint:first-child {
			margin-left: 0;
			padding-left: var(--min-touch-width);
		}
.snap-slider__snappoint:last-child {
			margin-right: 0;
			padding-right: var(--min-touch-width);
		}
.snap-slider__snappoint > .button--text {
			--spacing: var(--spacing--small);
		}
.snap-slider__button {
		padding: 0 var(--spacing--small);
		margin-bottom: var(--spacing);
	}
.snap-slider .chevron-button--previous {
		grid-area: left;
		padding-left: 0;
		padding-right: 0;
	}
.snap-slider .chevron-button--forward {
		grid-area: right;
		padding-left: 0;
		padding-right: 0;
	}
.modal {
	--modal-animation-duration: 0.4s;
	--modal-animation-timing-function: var(--ease-in-out-quad);
	--modal-background-color: var(--color-blue--medium);
	--modal-close-icon-color: var(--color-cyan);
	--modal-top: 50%;
	--modal-top-translate: -50%;
	--modal-window-max-width: 32rem;
	--modal-border-radius: 2rem;
	--modal-text-color: var(--color-white);
	--modal-padding: 2rem;
	display: none;
	margin: 0 auto;
	background: var(--modal-background-color);
	width: calc(100vw - var(--spacing--small) * 2);
	max-width: var(--modal-window-max-width);
	max-height: calc(100% - var(--spacing) * 2);
	border-radius: var(--modal-border-radius);
	border: none;
	padding: 0;
	box-shadow: var(--box-shadow-bottom--5-steps);
	animation-duration: var(--modal-animation-duration);
	animation-timing-function: var(--ease-in-out-quad);
	position: fixed;
	top: var(--modal-top);
	transform: translateY(var(--modal-top-translate));
	will-change: transform;
	align-content:start;
	justify-content:start;
	place-content:start;
	justify-content:start;
	overflow-x: hidden;
	overflow: auto;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
}
.modal--size-large {
			--modal-window-max-width: 50rem;
		}
.modal--position-bottom {
		--modal-top: calc(100% - var(--spacing));
		--modal-top-translate: -100%;
	}
.modal--position-top-right {
		--modal-top: calc(0% + var(--spacing));
		--modal-top-translate: 0%;
		margin-right: var(--spacing);
	}
@supports (padding: env(safe-area-inset-top)) {

	.client-is-ios .modal {
			max-height: calc(100% - var(--spacing--small) * 2);
	}

			.client-is-installed.client-is-ios .modal {
				max-height: calc(100% - env(safe-area-inset-top));
			}

			.client-is-ios .modal--position-bottom {
				top: calc(var(--modal-top) - env(safe-area-inset-bottom));
			}

			.client-is-ios .modal--position-top-right {
				top: calc(var(--modal-top) + env(safe-area-inset-top));
			}
		}
.modal[open] {
		display: flex;
		flex-direction: column;
		animation-name: show-dialog
	}
.modal[open]::backdrop {
			animation-name: show-backdrop;
		}
.modal.hide {
		animation-name: hide-dialog
	}
.modal.hide::backdrop {
			animation-name: hide-backdrop;
		}
.modal::backdrop {
		animation-name: none;
		/* using a custom property to set the duration does not work somehow */
		animation-duration: 0.4s;
		animation-fill-mode: forwards;
		animation-timing-function: var(--modal-animation-timing-function);
	}
.modal__header {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		padding: var(--spacing);
		align-items: center;
		color: var(--modal-text-color);
		position: sticky;
		top: 0;
		isolation: isolate;
		z-index: 10
	}
.modal__header::before {
			content: '';
			position: absolute;
			inset: 0;
			z-index: -1;
			background-color: var(--modal-background-color);
			opacity: 0.9;
		}
.modal__header:empty {
			display: none;
		}
.modal__content {
		color: var(--modal-text-color);
		text-align: center;
		display: flex;
		flex-direction: column;
		padding: 0 var(--modal-padding) var(--modal-padding);
	}
.modal__content > *:first-child {
			margin-top: var(--spacing--small);
		}
.modal__content > *:last-child {
			margin-bottom: 0;
		}
.modal__title {
		grid-column: 2 / 3;
		grid-row: 1 / -1;
		text-align: center;
	}
.modal__close {
		width: max-content;
		margin-left: var(--spacing--small);
		position: relative;
		cursor: pointer;
		grid-column: 3 / 4;
		grid-row: 1 / -1;
		justify-self: end;
		align-self: start
	}
.modal__close::before {
			content: '';
			position: absolute;
			top: calc(var(--spacing) * -1);
			right: calc(var(--spacing) * -1);
			bottom: calc(var(--spacing) * -1);
			left: calc(var(--spacing) * -1);
			z-index: 10;
		}
.modal__button {
		position: sticky;
		bottom: -1px;
		width: 100%;
		margin-top: 0;
		margin-bottom: 0;
		background-color: var(--color-cyan);
		box-shadow: none;
		border: 1px solid var(--modal-background-color);
		border-radius: var(--modal-border-radius);
		flex-shrink: 0
	}
.modal__button::before {
			border-radius: var(--modal-border-radius);
		}
.modal__button:hover,
		.modal__button:active {
			transform: none;
		}
.modal__success-icon {
		margin: var(--spacing) auto;
		display: block;
		width: 15em;
		/* max-width: var(--modal-window-max-width); */
		/* max-height: 50vh; */
		--checkbox-stroke-width: 6px;
		--checkbox-stroke-color: var(--color-cyan);
		--checkbox-delay: 500ms;
		--checkbox-duration: 3s;
		--checkbox-timing-function: var(--ease-in-out-quad);
		/* TODO: use dynamically calculated path lenght here */
		--checkbox-path-length: 10000;
		--checkmark-visibility-start: 0;
	}
.modal__success-icon circle,
		.modal__success-icon polyline {
			stroke-dashoffset: var(--checkbox-path-length);
			animation-name: successAnimation;
			animation-delay: var(--checkbox-delay);
			animation-duration: var(--checkbox-duration);
			animation-timing-function: var(--checkbox-timing-function);
			animation-fill-mode: forwards;
			stroke-dasharray: var(--checkbox-path-length);
		}
.modal__success-icon polyline {
			animation-delay: calc(var(--checkbox-delay) * 2.5);
			stroke-linejoin: round;
			stroke-linecap: round;
		}
@keyframes show-dialog {
	from {
		opacity: 0;
		transform: translateY(var(--modal-top-translate)) translateY(2rem);
	}

	to {
		opacity: 1;
		transform: translateY(var(--modal-top-translate));
	}
}
@keyframes hide-dialog {
	to {
		opacity: 0;
		transform: translateY(var(--modal-top-translate)) translateY(2rem);
	}
}
@keyframes show-backdrop {
	from {
		-webkit-backdrop-filter: blur(0);
		        backdrop-filter: blur(0);
	}

	to {
		-webkit-backdrop-filter: blur(10px);
		        backdrop-filter: blur(10px);
	}
}
@keyframes hide-backdrop {
	from {
		-webkit-backdrop-filter: blur(10px);
		        backdrop-filter: blur(10px);
	}

	to {
		-webkit-backdrop-filter: blur(0);
		        backdrop-filter: blur(0);
	}
}
@keyframes successAnimation {
	from {
		stroke-dashoffset: var(--checkbox-path-length);
	}

	to {
		stroke-dashoffset: 0;
	}
}
/* polyfill */
.modal[role='dialog'] {
	left: 0;
	right: 0
}
.modal[role='dialog']:not([open]) {
		display: none;
	}
.modal[role='dialog'] + .backdrop {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		animation-name: none;
		/* using a custom property to set the duration does not work somehow */
		animation-duration: 0.4s;
		animation-fill-mode: forwards;
		animation-timing-function: var(--modal-animation-timing-function);
	}
.modal[role='dialog'][open] + .backdrop {
			animation-name: show-backdrop;
		}
.modal[role='dialog'].hide + .backdrop {
			animation-name: hide-backdrop;
		}
._dialog_overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.image {
	flex-shrink: 0;
	position: relative
}
.image svg {
		position: absolute;
		bottom: var(--spacing--small);
		right: var(--spacing--small);
		box-shadow: var(--box-shadow-bottom--3-steps);
		border-radius: 50%;
	}
.switch-select {
	--bar-border-radius: 2rem;
	margin: var(--spacing) 0;
}
.switch-select__label {
		margin-bottom: var(--spacing--small);
	}
.switch-select__bar {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
		position: relative;
		z-index: 10
	}
.switch-select__bar::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			background-color: #000000;
			opacity: 0.333;
			width: 100%;
			height: 100%;
			border-radius: var(--bar-border-radius);
			z-index: -1;
		}
.switch-select__bar::after {
			content: '';
			border-radius: var(--bar-border-radius);
			position: absolute;
			background-color: var(--color-cyan);
			width: calc(100% / var(--items));
			height: 100%;
			left: 0;
			top: 0;
			transform: translateX(var(--indicator-position));
			transition: var(--transition-duration) var(--ease-out-back-less);
			transition-property: transform;
			z-index: -1;
			box-shadow: var(--box-shadow-bottom--4-steps);
		}
.switch-select__radiobutton {
		padding: var(--spacing--small);
		display: flex;
		justify-content: center;
		align-items: center;
	}
.switch-select .radiobutton {
		width: 100%;
		height: 100%;
	}
.switch-select .input__label {
		font-family: var(--font-alternative-medium);
		text-align: center;
		padding: 0 var(--spacing--small);
		width: 100%;
		height: 100%;
	}
.switch-select .input__pseudo-radiobutton {
		display: none;
	}
.selectable-bar {
	--selectable-bar-selected-opacity: 0;
	--selectable-bar-background-color: transparent;
	--selectable-bar-selected-background-color: var(--color-cyan);
	--selectable-bar-transition: 0.25s var(--ease-in-out-quad);
	--selectable-bar-headline-fontsize: var(--font-size-m);
	--selectable-bar-headline-font-family: var(--font-alternative-light);
	--selectable-bar-subheadline-fontsize: var(--font-size-s);
	--selectable-bar-icon-size: 2.5rem;
	--selectable-bar-headline-color: var(--color-white);
	--selectable-bar-opacity: 1;
	--selectable-bar-subheadline-color: var(--color-cyan);
	--selectable-bar-cursor: pointer;
	display: grid;
	position: relative;
	grid-template-areas: 'icon headline' 'icon subheadline';
	grid-template-columns: auto minmax(0, 100%);
	grid-template-rows: min-content min-content;
	align-items: center;
	z-index: 0;
	padding: var(--spacing);
	background-color: var(--selectable-bar-background-color);
}
.selectable-bar--selected {
		--selectable-bar-selected-opacity: 1;
		--selectable-bar-subheadline-color: var(--color-white);
	}
.selectable-bar-group .selectable-bar--selected {
			--selectable-bar-selected-opacity: 0;
		}
.selectable-bar--disabled {
		--selectable-bar-opacity: 0.25;
		--selectable-bar-cursor: not-allowed;
	}
.selectable-bar-group {
		--selectable-bar-selected-background-color: var(--color-cyan);
		border-top: 1px solid var(--color-blue);
	}
.selectable-bar-group .content-wrapper {
			padding: 0 var(--spacing) var(--spacing);
		}
.selectable-bar-group .accordion .accordion__head::before {
					content: '';
					top: 0;
					left: 0;
					position: absolute;
					width: 100%;
					height: calc(100% + var(--content-height));
					z-index: -1;
					background-color: var(--selectable-bar-selected-background-color);
					transition: var(--transition);
					transition-property: transform, opacity;
					opacity: 0;
					transform: scaleY(0.5);
					transform-origin: top center;
				}
.selectable-bar-group .accordion .accordion__head::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 1px;
					background-color: var(--color-blue);
					transition: var(--transition);
				}
.selectable-bar-group .accordion.accordion--open .accordion__head {
					height: calc(100% + var(--content-height))
				}
.selectable-bar-group .accordion.accordion--open .accordion__head::before {
						transform: scaleY(1);
						opacity: 1;
					}
.selectable-bar-group .accordion.accordion--open .accordion__head::after {
						transform: translateY(var(--content-height));
					}
.selectable-bar::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--selectable-bar-selected-background-color);
		opacity: var(--selectable-bar-selected-opacity);
		z-index: -1;
		transition: opacity var(--selectable-bar-transition);
	}
.selectable-bar__icon {
		grid-area: icon;
		width: var(--selectable-bar-icon-size);
		height: var(--selectable-bar-icon-size);
		opacity: var(--selectable-bar-opacity);
		margin-right: var(--spacing);
	}
.selectable-bar__radiobutton {
		opacity: var(--selectable-bar-opacity);
		grid-column: 2 / -1;
		grid-row: 1 / -1;
	}
.selectable-bar__subheadline + .selectable-bar__radiobutton {
			grid-area: headline;
		}
.selectable-bar__radiobutton::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			cursor: var(--selectable-bar-cursor);
			z-index: 10;
		}
.selectable-bar__radiobutton .input__pseudo-radiobutton {
			display: none;
		}
.selectable-bar__radiobutton .input__label {
			color: var(--selectable-bar-headline-color);
			font-family: var(--selectable-bar-headline-font-family);
			font-size: var(--selectable-bar-headline-fontsize);
			padding: 0;
		}
.selectable-bar__subheadline {
		grid-area: subheadline;
		color: var(--selectable-bar-subheadline-color);
		font-size: var(--selectable-bar-subheadline-fontsize);
		padding-top: var(--spacing--small);
		opacity: var(--selectable-bar-opacity);
	}
.compensation-shares {
	width: 100%;
	max-width: 30rem;
	margin: var(--spacing) auto;
	display: grid;
	grid-template-areas: 'left right';
	grid-template-columns: 1fr 1fr;
	justify-content: center;
	align-items: center;
	justify-items: center;
	position: relative;
	z-index: 0
}
.compensation-shares .circle {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		width: 100%
	}
.compensation-shares .circle::before {
			position: relative;
			display: block;
			font-size: 0; /* needed for certain browsers */
			grid-row: 1 / 2;
			grid-column: 1 / 2;
			pointer-events: none;
			z-index: -1;
			transform: scale(var(--share, 0));
			transition: transform 1s var(--ease-out-back);
		}
.compensation-shares .circle--employee {
			position: relative;
			grid-area: left;
			transform: translateX(var(--spacing--small));
			z-index: 10
		}
.compensation-shares .circle--employee::before {
				/* RGB value of --color-cyan used here, as custom properties are not allowed in URLs */
				content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><circle fill="rgb(0, 171, 231)" cx="50%" cy="50%" r="48"></circle></svg>');
			}
.compensation-shares .circle--employer {
			grid-area: right;
			transform: translateX(calc(0% - var(--spacing--small)))
		}
.compensation-shares .circle--employer::before {
				/* RGB value of ---color-black-transparent used here, as custom properties are not allowed in URLs */
				content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" ><circle vector-effect="non-scaling-stroke" stroke="rgb(255,255,255)" stroke-width="2" fill="rgba(0,0,0,0.15)" cx="50%" cy="50%" r="48"></circle></svg>');
			}
.compensation-shares .circle--employer-dashed::before {
					/* RGB value of ---color-black-transparent used here, as custom properties are not allowed in URLs; dashed-array added */
					content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" ><circle vector-effect="non-scaling-stroke" stroke="rgb(255,255,255)" stroke-width="1" stroke-dasharray="2" fill="rgba(0,0,0,0.15)" cx="50%" cy="50%" r="48"></circle></svg>');
				}
.compensation-shares .amount {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		text-align: center;
		z-index: 20;
	}
.compensation-shares .amount--employee {
			grid-area: left;
			transform: translateX(var(--spacing--small));
		}
.compensation-shares .amount--employer {
			grid-area: right;
			transform: translateX(calc(0% - var(--spacing--small)));
		}
.compensation-data-table {
	display: grid;
	align-items: baseline;
	grid-template-columns: 1fr 1fr;
	grid-gap: var(--spacing) var(--spacing);
	margin: var(--spacing) auto 0;
}
.breakpoint-medium .compensation-data-table {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
.compensation-data-table__currency {
		justify-self: end;
		grid-column-start: 1;
		grid-column-end: 2;
	}
.breakpoint-medium .compensation-data-table__currency {
			grid-column-end: 3;
		}
.compensation-data-table__currency-label {
		grid-column-start: 2;
		grid-column-end: 3;
	}
.breakpoint-medium .compensation-data-table__currency-label {
			grid-column-start: 3;
			grid-column-end: 5;
		}
.compensation-data-table__divider {
		grid-column: 1 / 3;
		height: 1px;
		background-color: var(--color-white);
		opacity: 0.2;
	}
.breakpoint-medium .compensation-data-table__divider {
			grid-column: 2 / 4;
		}
.compensation-data-table__own-contribution {
		grid-column: 1 / -1;
		/**
		 * width: auto; needed here to properly size the row when the accordion is opened; 
		 * the inherited 100% create a sizing bug 
		*/
		width: auto
	}
.compensation-data-table__own-contribution .accordion__head,
		.compensation-data-table__own-contribution .content-wrapper {
			margin: var(--spacing) auto 0;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: var(--spacing) var(--spacing);
		}
.breakpoint-medium .compensation-data-table__own-contribution .accordion__head, .breakpoint-medium .compensation-data-table__own-contribution .content-wrapper {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
.compensation-data-table__own-contribution .head-slot {
			/* only for browsers that don't support display contents */
			grid-column-start: 1;
			grid-column-end: -1;
			grid-row-start: 1;
			grid-template-columns: 1fr 1fr;
			display: grid;
			grid-gap: var(--spacing);
		}
.breakpoint-medium .compensation-data-table__own-contribution .head-slot {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
@supports (display: contents) {

		.compensation-data-table__own-contribution .head-slot {
				display: contents
		}
			}
.compensation-data-table__own-contribution .head-slot .compensation-data-table__currency-label {
				grid-row-start: 1;
				grid-column-end: 3;
				word-break: break-word;
				hyphens: auto;
			}
.breakpoint-medium .compensation-data-table__own-contribution .head-slot .compensation-data-table__currency-label {
					grid-column-end: 4;
				}
.compensation-data-table__own-contribution .icon-slot {
			/* only for browsers that don't support display contents */
			grid-column: 2 / 3;
			grid-row-start: 1;
		}
.breakpoint-medium .compensation-data-table__own-contribution .icon-slot {
				grid-column-start: 4;
				grid-column-end: 5;
			}
@supports (display: contents) {

		.compensation-data-table__own-contribution .icon-slot {
				display: contents
		}
			}
.compensation-data-table__own-contribution .icon-slot svg {
				grid-row-start: 1;
				grid-column: 2 / 3;
				justify-self: end;
			}
.breakpoint-medium .compensation-data-table__own-contribution .icon-slot svg {
					justify-self: start;
					grid-column: 4 / 5;
				}
.compensation-data-table__gross-amount {
		justify-self: end;
		grid-column-start: 1;
		grid-column-end: 3;
	}
.compensation-data-table__disclaimer {
		grid-column: 1 / -1;
	}
.big-number-input .input__tag {
		text-align: center;
		color: var(--color-cyan);
		font-family: var(--font-alternative-medium);
		font-size: var(--font-size-m);
	}
.form .select-input,
	.form .textarea,
	.form .checkbox,
	.form .radiobutton-group,
	.form .switch-select {
		max-width: var(--input-width);
		margin: var(--spacing) auto;
	}
.form .input--read-only { 
		opacity:0.333;
	}
.password-strength{
	/* max-width: var(--input-width); */
	margin:0 auto;
	width: 100%;
}
.textarea {
	width: 100%;
	max-width: var(--input-width);
}
.textarea textarea {
		width: 100%;
		border: 1px solid var(--color-white);
		padding: var(--spacing--small);
		background: transparent;
		color: var(--color-white);
	}
.pension-assets-chart {
	display: grid;
	grid-template-columns: min-content minmax(0, 100%) min-content;
	padding-left: calc(var(--spacing) * 2);
}
.pension-assets-chart__chart {
		grid-row: 1 / -1;
		grid-column: 1/ -1
	}
.pension-assets-chart__chart svg {
			position: absolute;
			overflow: visible;
		}
.pension-assets-chart__pan {
		position: relative;
		padding: calc(var(--spacing) * 2) var(--spacing) 0;
		height: 50%;
		width: min-content;
		align-self: end;
		margin: 0;
		z-index: 10;
		grid-row: 1 / -1
	}
.pension-assets-chart__pan[disabled] {
			opacity: 0;
		}
.pension-assets-chart__pan--left {
			grid-column: 1 / 2;
			justify-self: start;
		}
.pension-assets-chart__pan--right {
			grid-column: 3 / 4;
			justify-self: end;
		}
.pension-assets-dashboard {
	display: grid;
	grid-template-columns: auto auto;
	grid-row-gap: var(--spacing--small);
	grid-column-gap: var(--spacing);
	align-items: center;
	width: calc(100% + var(--tile-inner-spacing));
	margin-left: calc(var(--tile-inner-spacing) * -1);
	grid-auto-rows: min-content;
	padding: var(--spacing--small) 0;
}
.pension-assets-dashboard .big-color-figure__figure {
		padding-left: var(--tile-inner-spacing);
		font-size: var(--font-size-m);
	}
.tooltip {
	--tooltip-icon-color: var(--color-white);
	display: inline-flex;
	vertical-align: text-top;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	align-content: center;
	justify-content: center;
	place-content: center;
	padding: 0;
}
.tooltip__label {
		color: var(--tooltip-icon-color);
		text-decoration: underline;
	}
.tooltip .icon {
		padding: 0 var(--spacing--small);
	}
.tan-service-button {
	background: var(--color-white);
	color: var(--color-cyan);
}
.authentication-modal {
	display: grid;
	grid-template-areas: 'title' 'fields' 'error' 'submit';
	grid-template-rows: minmax(min-content, 100%) min-content min-content minmax(min-content, 100%)
}
.authentication-modal > * {
		padding: 0 var(--spacing);
	}
.authentication-modal__form {
		display: contents;
	}
.authentication-modal__title {
		grid-area: title;
		padding: 0 var(--spacing);
	}
.authentication-modal__fields {
		grid-area: fields;
		padding: 0 var(--spacing);
	}
.authentication-modal__error {
		padding: 0 var(--spacing);
		grid-area: error;
	}
.authentication-modal__submit {
		margin-bottom: 0;
		grid-area: submit;
		position: sticky;
		bottom: 0;
		width: 100%;
		box-shadow: none;
		border: 1px solid var(--modal-background-color)
	}
.authentication-modal__submit:hover,
		.authentication-modal__submit:active {
			transform: none;
		}
.badge {
	--badge-transition: 0.25s var(--ease-in-out-quad);
	--badge-dimension: 2rem;
	--badge-color: var(--color-blue--medium);
	--badge-background-color: var(--color-cyan);
	--badge-padding: 0.25rem;
	position: absolute;
	display: inline-flex;
	color: var(--badge-color);
	background-color: var(--badge-background-color);
	transition: opacity var(--error-transition);
	align-content: center;
	justify-content: center;
	place-content: center;
	opacity: 0;
	min-width: calc(var(--badge-dimension) + var(--badge-padding) * 2);
	height: calc(var(--badge-dimension) + var(--badge-padding) * 2);
	font-size: calc(var(--badge-dimension) * 0.75);
	border-radius: calc(var(--badge-dimension) + var(--badge-padding) * 2);
	padding: var(--badge-padding) calc(var(--badge-padding) * 2);
}
.badge--visible {
		opacity: 1;
	}
/* TODO: Component Refactor */
.dashboard-tile {
	display: grid;
	position: relative;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	--tile-inner-spacing: calc(var(--spacing) * 0.75);
	--tile-background-color: var(--color-blue--medium);
	z-index: 0;
	text-decoration: none
}
.dashboard-tile::before {
		position: relative;
		content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"></svg>');
		display: block;
		font-size: 0; /* needed for certain browsers */
		grid-row: 1 / -1;
		grid-column: 1 / -1;
		pointer-events: none;
		z-index: -1;
	}
.dashboard-tile__content {
		grid-row: 1 / -1;
		grid-column: 1 / -1;
		position: absolute;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: grid;
		grid-template-areas: 'top' 'center' 'bottom';
		grid-template-rows: min-content minmax(0, 100%) min-content;
		box-shadow: var(--box-shadow-right--5-steps);
		background-color: var(--tile-background-color);
		border-radius: 1rem;
		text-decoration: none;
		padding: var(--tile-inner-spacing);
	}
/*
	&--xl {
		grid-column: span 4;
		grid-row: span 4;

		.breakpoint-medium & {
			grid-column: span 2;
			grid-row: span 2;
		}
	} */
.dashboard-tile--l {
		/* grid-column: span 4;
		grid-row: span 2; */
	}
.dashboard-tile--l::before {
			content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 1"></svg>');
		}
.dashboard-tile--l {

		/* .breakpoint-medium & {
			grid-column: span 2;
			grid-row: span 1;
		} */
	}
.dashboard-tile--m {
		/* grid-column: span 2;
		grid-row: span 2;

		.breakpoint-medium & {
			grid-column: span 1;
			grid-row: span 1;
		} */
	}
.dashboard-tile--sm {
		/* grid-column: span 2;
		grid-row: span 1; */
	}
.dashboard-tile--sm::before {
			content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 1"></svg>');
		}
/* grid-column: span 1;
		grid-row: span 1; */
.dashboard-tile--s .dashboard-tile__content {
			padding: 0;
		}
.dashboard-tile--s .dashboard-tile__content > .icon {
				display: none;
			}
.breakpoint-medium .dashboard-tile--s .dashboard-tile__content > .icon {
					display: block;
				}
.breakpoint-medium .dashboard-tile--s .dashboard-tile__content {
				padding: var(--tile-inner-spacing);
			}
.dashboard-tile--s .title {
			display: none;
		}
.breakpoint-medium .dashboard-tile--s .title {
				display: inline;
			}
.breakpoint-medium .dashboard-tile--bp-medium--s {
			/* grid-column: span 1;
			grid-row: span 1; */
		}
.breakpoint-medium .dashboard-tile--bp-medium--s::before {
				content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"></svg>');
			}
.breakpoint-medium .dashboard-tile--bp-medium--sm {
			/* grid-column: span 2;
			grid-row: span 1; */
		}
.breakpoint-medium .dashboard-tile--bp-medium--sm::before {
				content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 1"></svg>');
			}
.dashboard-tile__top {
		position: relative;
		grid-area: top;
		z-index: 10;
	}
.dashboard-tile__top .title {
			font-size: var(--font-size-xs);
		}
.breakpoint-smallMedium .dashboard-tile__top .title {
				font-size: var(--font-size-s);
			}
.breakpoint-medium .dashboard-tile__top .title {
				font-size: var(--font-size-default);
			}
.breakpoint-large .dashboard-tile__top .title {
				font-size: var(--font-size-m);
			}
.dashboard-tile__center {
		grid-area: center;
		display: flex;
		align-items: center;
		justify-items: center;
		place-items: center;
		align-content: center;
		justify-content: center;
		place-content: center;
	}
.dashboard--indices-comparison .dashboard-tile__center {
			position: relative;
			grid-column: 1 / -1;
			margin-left: calc(var(--tile-inner-spacing) * -2);
			transform: translateX(var(--tile-inner-spacing));
		}
.dashboard-tile__total {
		grid-row: 1 / -1;
		grid-column: 1 / -1;
		margin-top: calc(var(--tile-inner-spacing) * -2);
		margin-left: calc(var(--tile-inner-spacing) * -2);
		transform: translateY(var(--tile-inner-spacing)) translateX(var(--tile-inner-spacing));
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
.dashboard-tile__total .pointer-evnts--force-none {
			width: 100%;
			height: 100%;
		}
.dashboard-tile--s .dashboard-tile__total {
			margin-top: 0;
			margin-left: 0;
			transform: none;
			align-items: center;
			justify-items: center;
			place-items: center;
			display: flex;
			align-content: center;
			justify-content: center;
			place-content: center;
		}
.dashboard-tile__bottom {
		grid-area: bottom;
		align-self: end;
		justify-self: end
	}
.dashboard-tile__bottom .icon {
			--svg-color: var(--color-cyan);
		}
.dashboard-tile .badge {
		top: calc(var(--spacing--small) * -1);
		right: calc(var(--spacing--small) * -1);
		z-index: 20;
	}
.dashboard-tile__indice-comparison-status {
		position: relative;
		z-index: 10;
		display: grid;
		grid-template-columns: 1fr auto;
		justify-content: end;
		align-items: center;
		align-content: end;
		grid-gap: 0 var(--spacing);
		gap: 0 var(--spacing);
	}
.dashboard-tile__indice-comparison-status > span:last-child {
			grid-column: 1 / -1;
		}
.pointer-events--force-none {
	width: 100%;
	height: 100%;
}
.dashboard--indices-comparison {
		grid-column: 1 / 5;
		grid-row: 2 / 4;
	}
.dashboard--fund-composition {
		grid-column: 1 / 3;
		grid-row: 4 / 6;
	}
.dashboard--fund-composition img {
			object-fit: contain;
			position: absolute;
			width: 95%;
			height: 95%;
		}
.dashboard--blocks-account {
		grid-column: 1 / 3;
		grid-row: 10 / 11;
	}
.dashboard--blocks-account svg {
			height: 100%;
			max-height: 13rem;
		}
.dashboard--blocks-account.dashboard-tile--user-status-FE-VO19 {
			display: none;
		}
.breakpoint-medium .dashboard--blocks-account {
			grid-column: 3 / 5;
			grid-row: 8/9;
		}
.breakpoint-large .dashboard--blocks-account {
			grid-column: 3 / 5;
			grid-row: 6/7
		}
.breakpoint-large .dashboard--blocks-account.dashboard-tile--user-status-Leistungsfall {
				grid-column: 3 / 5;
				grid-row: 4 / 6;
			}
.dashboard--contact {
		grid-column: 3 / 4;
		grid-row: 4 / 5
	}
.dashboard--contact.dashboard-tile--user-status-Leistungsfall {
			grid-column: 1 / 3;
			grid-row: 3 / 4;
		}
.dashboard--contact .dashboard-tile__total img {
				object-fit: contain;
				position: absolute;
				width: 80%;
				height: 80%;
				max-width: 80px;
			}
.dashboard--contact .title {
			display: none;
		}
.breakpoint-medium .dashboard--contact .title {
				display: inline;
			}
.dashboard--payout-model svg {
			height: 100%;
			max-height: 13rem;
		}
.dashboard--risk-benefit {
		grid-column: 4 / 5;
		grid-row: 4 / 5;
	}
.dashboard--risk-benefit .dashboard-tile__total svg {
				object-fit: contain;
				position: absolute;
				width: 80%;
				height: 80%;
				max-width: 100px;
			}
.dashboard--risk-benefit .title {
			display: none;
		}
.breakpoint-medium .dashboard--risk-benefit .title {
				display: inline;
			}
.dashboard--conversions {
		grid-column: 1 / 3;
		grid-row: 7 / 8;
	}
.dashboard--conversions img {
			position: absolute;
			object-fit: contain;
			width: 100%;
			height: 80%;
		}
.dashboard--pension-assets {
		grid-column: 1 / 5;
		grid-row: 6 / 7;
	}
.dashboard--profile .dashboard-tile__total svg {
				object-fit: contain;
				position: absolute;
				width: 50%;
				height: 50%;
				max-width: 100px;
			}
.dashboard--documents {
		grid-column: 4 / 5;
		grid-row: 5 / 5
	}
.dashboard--documents.dashboard-tile--user-status-Leistungsfall {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
		}
.dashboard--documents .dashboard-tile__total svg {
				object-fit: contain;
				position: absolute;
				width: 50%;
				height: 50%;
				max-width: 100px;
			}
.dashboard--deferred-compensation {
		grid-column: 1 / 5;
		grid-row: 8 / 10;
	}
.dashboard--deferred-compensation img {
			object-fit: contain;
			position: absolute;
			width: 80%;
			height: 80%;
		}
.dashboard--faq {
		grid-column: 3 / 5;
		grid-row: 7 / 8
	}
.dashboard--faq.dashboard-tile--user-status-Leistungsfall {
			grid-column: 1 / 3;
			grid-row: 5 / 6;
		}
.dashboard--payout-model {
		grid-column: 3 / 5;
		grid-row: 10 / 11
	}
.dashboard--payout-model.dashboard-tile--user-status-Leistungsfall {
			grid-column: 3 / 5;
			grid-row: 2 / 4;
		}
.dashboard--payout-model .dim {
			opacity: 0.3;
		}
.dashboard--payout-model .badge {
			top: calc(var(--spacing--small) * -2);
			right: calc(var(--spacing--small) * -2);
			z-index: 20;
			background-color: var(--error-message-icon-color);
		}
.dashboard--payout-model .in-progress-indicator {
			display: flex;
			justify-content: space-between;
		}
.breakpoint-medium .dashboard--payout-model .badge {
				top: calc(var(--spacing--small) * -4);
				right: calc(var(--spacing--small) * -4);
			}
.breakpoint-medium .dashboard--payout-model {
			grid-column: 3 / 5;
			grid-row: 7 / 8;
}
.breakpoint-medium .dashboard--payout-model.dashboard-tile--user-status-Vorstand {
				grid-column: 1 / 3;
				grid-row: 5 / 7;
			}
.breakpoint-medium .dashboard--payout-model.dashboard-tile--user-status-Leistungsfall {
				grid-column: 3 / 5;
				grid-row: 4 / 7;
			}
.breakpoint-large .dashboard--payout-model {
			grid-column: 5 / 7;
			grid-row: 6 / 7
		}
.breakpoint-large .dashboard--payout-model.dashboard-tile--user-status-Vorstand {
				grid-column: 3 / 5;
				grid-row: 3 / 5;
			}
.breakpoint-large .dashboard--payout-model.dashboard-tile--user-status-Leistungsfall {
				grid-column: 4 / 6;
				grid-row: 4 / 6;
			}
@supports (-ms-ime-align: auto) {
	.breakpoint-small .dashboard {
			grid-template-columns: 1fr;
	}

			.breakpoint-small .dashboard__title {
				grid-column: span 1;
			}

			.breakpoint-small .dashboard-tile {
				grid-column: auto;
				grid-row: auto;
			}

			.breakpoint-small .dashboard--payout-model {
			}
		}
@supports (-ms-ime-align: auto) {
		.breakpoint-smallMedium .dashboard {
			grid-template-columns: repeat(2, 1fr);
		}

			.breakpoint-smallMedium .dashboard__title {
				grid-column: 1 / 3;
			}

			.breakpoint-smallMedium .dashboard-tile {
				grid-column: auto;
				grid-row: auto;
			}

			.breakpoint-smallMedium .dashboard--indices-comparison {
				grid-column: 1 / 3;
			}
	}
.breakpoint-medium .dashboard--indices-comparison {
			grid-column: 1 / 3;
			grid-row: 2 / 4;
		}
.breakpoint-medium .dashboard--conversions {
			grid-column: 3 / 5;
			grid-row: 2 / 3;
		}
.breakpoint-medium .dashboard--pension-assets {
			grid-column: 3 / 5;
			grid-row: 3 / 4
		}
.breakpoint-medium .dashboard--pension-assets.dashboard-tile--user-status-Leistungsfall {
				grid-column: 1 / 3;
				grid-row: 3 / 4;
			}
.breakpoint-medium .dashboard--documents {
			grid-column: 1 / 2;
			grid-row: 4 / 5;
		}
.breakpoint-medium .dashboard--contact {
			grid-column: 2 / 3;
			grid-row: 4 / 5;
		}
.breakpoint-medium .dashboard--risk-benefit {
			grid-column: 3 / 4;
			grid-row: 4 / 5;
		}
.breakpoint-medium .dashboard--profile {
			grid-column: 4 / 5;
			grid-row: 4 / 5
		}
.breakpoint-medium .dashboard--profile.dashboard-tile--user-status-Leistungsfall {
				grid-column: 2 / 3;
				grid-row: 5 / 6;
			}
.breakpoint-medium .dashboard--deferred-compensation {
			grid-column: 1 / 3;
			grid-row: 5 / 7;
		}
.breakpoint-medium .dashboard--faq {
			grid-column: 1 / 3;
			grid-row: 7 / 8
		}
:not(.breakpoint-large).breakpoint-medium .dashboard--faq.dashboard-tile--user-status-LeftWithEntitlement {
					grid-column: 3 / 5;
					grid-row: 5 / 6;
				}
.breakpoint-medium .dashboard--faq.dashboard-tile--user-status-Leistungsfall {
				grid-column: 1 / 2;
				grid-row: 5 / 6;
			}
.breakpoint-medium .dashboard--fund-composition {
			grid-column: 3 / 5;
			grid-row: 5 / 7
		}
:not(.breakpoint-large).breakpoint-medium .dashboard--fund-composition.dashboard-tile--user-status-LeftWithEntitlement {
					grid-column: 1 / 3;
				}
@supports (-ms-ime-align: auto) {
	.breakpoint-medium .dashboard {
			grid-template-colums: 2;
	}

			.breakpoint-medium .dashboard__title {
				grid-column: span 2;
			}

			.breakpoint-medium .dashboard-tile {
				grid-column: auto;
				grid-row: auto;
			}

			.breakpoint-medium .dashboard--faq {
				grid-column: span 2;
			}
		}
.breakpoint-large .dashboard--indices-comparison {
			grid-column: 1 / 3;
			grid-row: 2 / 4;
		}
.breakpoint-large .dashboard--conversions {
			grid-column: 3 / 5;
			grid-row: 2 / 3;
		}
.breakpoint-large .dashboard--pension-assets {
			grid-column: 5 / 7;
			grid-row: 2 / 3
		}
.breakpoint-large .dashboard--pension-assets.dashboard-tile--user-status-Leistungsfall {
				grid-column: 1 / 3;
				grid-row: 3 / 4;
			}
.breakpoint-large .dashboard--profile {
			grid-column: 5 / 6;
			grid-row: 5 / 6
		}
.breakpoint-large .dashboard--profile.dashboard-tile--user-status-LeftWithEntitlement {
				grid-column: 3 / 4;
				grid-row: 4 / 5;
			}
.breakpoint-large .dashboard--profile.dashboard-tile--user-status-Leistungsfall {
				grid-column: 2 / 3;
				grid-row: 5 / 6;
			}
.breakpoint-large .dashboard--documents {
			grid-column: 1 / 3;
			grid-row: 4 / 5;
		}
.breakpoint-large .dashboard--contact {
			grid-column: 6 / 7;
			grid-row: 5 / 6
		}
.breakpoint-large .dashboard--contact.dashboard-tile--user-status-LeftWithEntitlement {
				grid-column: 4 / 5;
				grid-row: 4 / 5;
			}
.breakpoint-large .dashboard--contact.dashboard-tile--user-status-Leistungsfall {
				grid-column: 3 / 4;
				grid-row: 4 / 6;
			}
.breakpoint-large .dashboard--risk-benefit {
			grid-column: 3 / 5;
			grid-row: 5 / 6
		}
.breakpoint-large .dashboard--risk-benefit.dashboard-tile--user-status-LeftWithEntitlement {
				grid-row: 3 / 4;
			}
.breakpoint-large .dashboard--deferred-compensation {
			grid-column: 3/ 5;
			grid-row: 3 / 5;
		}
.breakpoint-large .dashboard--faq {
			grid-column: 1 / 3;
			grid-row: 5 / 6;
		}
.breakpoint-large .dashboard--fund-composition {
			grid-column: 5 / 7;
			grid-row: 3 / 5;
		}
.breakpoint-large .dashboard__title {
			grid-column: span 3;
		}
@supports (-ms-ime-align: auto) {
	.breakpoint-large .dashboard {
			grid-template-columns: repeat(3, 1fr);
	}

			.breakpoint-large .dashboard-tile {
				grid-row: auto;
				grid-column: auto;
			}
		}
.faq-changed-modal__show-all {
		--svg-color: var(--color-cyan);
		--text-color: var(--color-cyan);
		margin-left: auto;
		justify-self: flex-start;
	}
.faq-changed-modal__show-all span {
			display: flex;
			align-items: center;
		}
.faq-changed-modal__show-all svg {
			margin-left: 1rem;
		}
.faq-changed-modal__buttons {
		margin-top: var(--spacing);
	}
.faq-changed-modal__accept {
		--text-color: #ffffff;
		width: 100%;
	}
.faq-changed-modal__checkbox {
		--checkbox-stroke-color: var(--color-cyan);
	}
.faq-changed-modal .button--secondary {
		--text-color: var(--color-cyan);
	}
.faq-changed-modal .button--primary {
		color: #ffffff;
	}
.terms-and-conditions-modal .modal__content > * {
			padding: 0 var(--spacing);
		}
.terms-and-conditions-modal .modal__content ul,
		.terms-and-conditions-modal .modal__content ol {
			padding-left: var(--spacing);
		}
.terms-and-conditions-modal .modal__content > ul {
			padding-left: calc(var(--spacing) * 2);
		}
.terms-and-conditions-modal .modal__content > ol {
			counter-reset: headline-counter;
			list-style-type: none;
		}
.terms-and-conditions-modal .modal__content > ol > li {
				counter-increment: headline-counter;
			}
.terms-and-conditions-modal .modal__content > ol > li > h2::before {
						content: counter(headline-counter) '. ';
					}
.terms-and-conditions-modal--update {
	--modal-background-color: #ffffff;
	--modal-text-color: var(--color-blue--super-dark);
	--text-color: var(--modal-text-color);
}
.terms-and-conditions-modal--update .modal__content p,
		.terms-and-conditions-modal--update .modal__content li {
			text-align: left;
		}
.terms-and-conditions-modal--update h1,
	.terms-and-conditions-modal--update h2,
	.terms-and-conditions-modal--update h3 {
		color: var(--color-cyan);
	}
.whole-terms-and-conditions h1, .whole-terms-and-conditions h2 {
		text-align:left;
	}
.whole-terms-and-conditions .sticky-overlay {
		height: 100px;
		position: sticky;
		bottom: 0;
	
		/* background: linear-gradient(to top, var(--color-cyan) 10%, transparent); */
	}
.version-number {
	font-size: var(--font-size-xs);
	display: flex;
	align-self: flex-end;
	opacity: 0.2;
	padding: var(--spacing--small) var(--spacing) 0;
}
.captcha {
	margin: var(--spacing) auto;
	display: flex;
	justify-content: center;
}
.install-prompt-modal .modal__header {
			grid-template-columns: min-content auto min-content;
		}
.install-prompt-modal .modal__title {
			text-align: left;
		}
.install-prompt-modal .modal__close {
			margin-left: var(--spacing);
		}
.install-prompt-modal .modal__video {
			position: relative;
			z-index: 0;
			--video-opacity: 0;
		}
.install-prompt-modal .modal__video--visible {
				--video-opacity: 1;
			}
.install-prompt-modal .modal__video::before {
				content: '';
				position: absolute;
				height: 20%;
				width: 100%;
				background: linear-gradient(to bottom, hsl(202.13, 100%, 23.92%), hsla(202.13, 100%, 23.92%, 0.98032) 9.99%, hsla(202.13, 100%, 23.92%, 0.92593) 19.07%, hsla(202.13, 100%, 23.92%, 0.84375) 27.44%, hsla(202.13, 100%, 23.92%, 0.74074) 35.26%, hsla(202.13, 100%, 23.92%, 0.62384) 42.72%, hsla(202.13, 100%, 23.92%, 0.5) 50%, hsla(202.13, 100%, 23.92%, 0.37616) 57.28%, hsla(202.13, 100%, 23.92%, 0.25926) 64.74%, hsla(202.13, 100%, 23.92%, 0.15625) 72.56%, hsla(202.13, 100%, 23.92%, 0.07407) 80.93%, hsla(202.13, 100%, 23.92%, 0.01968) 90.01%, hsla(202.13, 100%, 23.92%, 0));
				z-index: 10;
			}
.install-prompt-modal .modal__video::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				height: 10%;
				width: 100%;
				background: linear-gradient(to top, hsl(202.13, 100%, 23.92%), hsla(202.13, 100%, 23.92%, 0.98032) 9.99%, hsla(202.13, 100%, 23.92%, 0.92593) 19.07%, hsla(202.13, 100%, 23.92%, 0.84375) 27.44%, hsla(202.13, 100%, 23.92%, 0.74074) 35.26%, hsla(202.13, 100%, 23.92%, 0.62384) 42.72%, hsla(202.13, 100%, 23.92%, 0.5) 50%, hsla(202.13, 100%, 23.92%, 0.37616) 57.28%, hsla(202.13, 100%, 23.92%, 0.25926) 64.74%, hsla(202.13, 100%, 23.92%, 0.15625) 72.56%, hsla(202.13, 100%, 23.92%, 0.07407) 80.93%, hsla(202.13, 100%, 23.92%, 0.01968) 90.01%, hsla(202.13, 100%, 23.92%, 0));
				z-index: 10;
			}
.install-prompt-modal .modal__video video {
				opacity: var(--video-opacity);
				transition: opacity 0.5s var(--ease-in-out-quad) 0.5s;
				margin-top: 1px; /* prevents 1px flicker */
				max-width: 100%;
				height: auto;
				z-index: -1;
				background-color: transparent;
			}
.install-prompt-modal__howto {
		display: grid;
		grid-template-columns: 1fr min-content;
		grid-column-gap: var(--spacing);
		padding: 0 var(--spacing);
		margin-bottom: var(--spacing);
		align-items: center;
		font-family: var(--font-alternative-light);
		font-weight: normal
	}
.install-prompt-modal__howto ol {
			counter-reset: howToCounter;
			margin: 0;
			padding: 0;
			vertical-align: baseline;
			list-style: none
		}
.install-prompt-modal__howto ol li {
				vertical-align: baseline;
				counter-increment: howToCounter;
				padding: 0 0 0 1em;
				text-indent: -1em
			}
.install-prompt-modal__howto ol li::before {
					content: counter(howToCounter) '. ';
					display: inline-block;
					width: 1em;
					padding-right: 0.5em;
					text-align: right;
					font-family: var(--font-alternative-medium);
					font-weight: 500;
					vertical-align: baseline;
				}
.install-prompt-modal__howto ol .icon {
				vertical-align: sub;
			}
.loading-spinner {
	--loading-spinner-dimension: 2rem;
	--loading-spinner-dash-offset: 187;
	--loading-spinner-dash-offset-midway: 46.75;
	--loading-spinner-animation-duration: 1.4s;
	position: absolute;
	display: block;
	z-index: 100000000;
	width: var(--loading-spinner-dimension);
	height: var(--loading-spinner-dimension);
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	opacity: 0;
	transition: opacity 0.25s var(--ease-in-out-quad);
	pointer-events: none;
	--animation-name-wrapper: none;
	--animation-name-dash: none;
	--animation-name-colors: none;
}
.loading-spinner--default {
		left: auto;
		top: 0;
		right: 0;
		transform: translateX(calc(15vw / -2)) translateX(50%) translateY(var(--header-height))
	}
@supports (padding: env(safe-area-inset-top)) {

	.loading-spinner--default {
			top: calc(env(safe-area-inset-top) * -1)
	}
		}
.breakpoint-medium .loading-spinner--default {
			right: var(--spacing);
			transform: translateY(-50%) translateY(calc(var(--header-height) / 2));
		}
.loading-spinner--center {
		position: fixed;
		--loading-spinner-dimension: 5rem;
	}
.breakpoint-medium .loading-spinner--center {
			margin-left: calc(var(--navigation-width--static) / 2);
		}
.loading-spinner--visible {
		opacity: 1;
	}
.loading-spinner--animate {
		--animation-name-wrapper: loading-spinner-rotator;
		--animation-name-dash: loading-spinner-dash;
		--animation-name-colors: loading-spinner-colors;
	}
.loading-spinner--mfa {
		top: var(--spacing);
		left: var(--spacing);
		transform: none;
	}
.loading-spinner__wrapper {
		width: 100%;
		height: 100%;
		animation: var(--animation-name-wrapper) var(--loading-spinner-animation-duration) linear infinite;
	}
.loading-spinner__circle {
		stroke-dasharray: var(--loading-spinner-dash-offset);
		stroke-dashoffset: 0;
		transform-origin: center;
		animation: var(--animation-name-dash) var(--loading-spinner-animation-duration) ease-in-out infinite,
			var(--animation-name-colors) calc(var(--loading-spinner-animation-duration) * 4) ease-in-out infinite;
	}
/* changing opacity is required to prevent glitch in safari */
@keyframes loading-spinner-rotator {
	0% {
		transform: rotate(0deg);
		opacity: 0;
	}

	10% {
		opacity: 1;
	}

	90% {
		opacity: 1;
	}

	100% {
		transform: rotate(270deg);
		opacity: 0;
	}
}
@keyframes loading-spinner-colors {
	0% {
		stroke: var(--color-white);
	}

	25% {
		stroke: var(--color-cyan);
	}

	50% {
		stroke: var(--color-white);
	}

	75% {
		stroke: var(--color-cyan);
	}

	100% {
		stroke: var(--color-white);
	}
}
@keyframes loading-spinner-dash {
	0% {
		stroke-dashoffset: var(--loading-spinner-dash-offset);
	}

	50% {
		stroke-dashoffset: var(--loading-spinner-dash-offset-midway);
		transform: rotate(135deg);
	}

	100% {
		stroke-dashoffset: var(--loading-spinner-dash-offset);
		transform: rotate(450deg);
	}
}
.document {
	--document-unread-opacity: 0;
	--document-unread-dimension: 0.625rem;
	--document-title-offset: calc((var(--document-unread-dimension) + var(--spacing--small) * 2) * -1);
	position: relative;
	display: grid;
	width: 100%;
	grid-template-areas: 'unread-indicator title title' 'date date link';
	grid-template-columns: auto 1fr auto;
	grid-template-rows: auto auto;
	grid-column-gap: var(--spacing--small);
	column-gap: var(--spacing--small);
	grid-row-gap: var(--spacing--small);
	row-gap: var(--spacing--small);
	padding: var(--spacing--small) var(--spacing) var(--spacing--small) var(--spacing);
	margin-bottom: var(--spacing--small);
	color: var(--color-white);
	font-family: var(--font-alternative--light);
	z-index: 0;
}
.breakpoint-smallMedium .document {
		grid-template-areas: 'unread-indicator title link' 'date date link';
	}
.breakpoint-medium .document {
		grid-template-areas: 'unread-indicator title date link';
		grid-template-columns: auto 1fr auto auto;
		grid-template-rows: auto;
		align-items: center;
	}
.document::before {
		content: '';
		position: relative;
		grid-area: unread-indicator;
		width: var(--document-unread-dimension);
		height: var(--document-unread-dimension);
		background-color: var(--color-cyan);
		border-radius: 100%;
		top: 0.5em;
		opacity: var(--document-unread-opacity);
		transition: opacity 0.25s var(--ease-in-out-quad);
		margin-right: var(--spacing--small);
	}
.breakpoint-medium .document::before {
			top: 0;
		}
.document--unread {
		--document-unread-opacity: 1;
		--document-title-offset: 0;
	}
.document::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: var(--color-black);
		opacity: 0.333;
		z-index: -1;
		box-shadow: var(--box-shadow-bottom--3-steps);
		border-radius: var(--border-radius);
	}
.document__title {
		grid-area: title;
		font-size: var(--font-size-default);
		transform: translateX(var(--document-title-offset));
		transition: transform 0.25s var(--ease-in-out-quad);
	}
.document__date {
		grid-area: date;
		font-size: var(--font-size-s);
		align-self: center;
	}
.document__link {
		grid-area: link;
		white-space: nowrap;
		color: var(--color-white);
		margin-right: 0;
		align-self: center
	}
.document__link[disabled] {
			opacity: 0;
		}
.document .loading-spinner {
		grid-area: link;
		align-self: center;
		justify-self: center;
		place-self: center;
	}
.chapter-two-summary.payout-form-summary p {
		margin-left: 0;
		max-width: 100%
	}
.chapter-two-summary.payout-form-summary p.subheadline {
			border-top: none;
		}
.chapter-two-summary.payout-form-summary .payout-form-summary__section {
		border-top: none;
	}
.payout-form-summary__section .value {
		word-wrap: break-word;
	}
.payout-confirmation .accordion__head {
		border-top: 1px solid var(--color-cyan);
	}
.payout-confirmation .accordion__content {
		padding-bottom: var(--spacing--large);
	}
.payout-confirmation .footer {
		padding-top:var(--spacing);
		border-top:1px solid var(--color-cyan);
	}
.indice-comparison-chart {
	display: flex;
	padding-left: calc(var(--spacing) * 2);
}
.indice-comparison-chart__chart > svg {
			position: absolute;
			overflow: visible;
		}
.hint {
	background-color: var(--color-white);
	color: var(--color-blue);
	padding: 0 var(--spacing) var(--spacing);
	border-radius: var(--border-radius);
	margin: var(--spacing) 0;
}
.hint__icon {
		margin: var(--spacing--small);
		--icon-size: 3rem;
	}
.payout-date .date-input {
		background-color:transparent !important;
		color:#ffffff;
		border:none;
		font-size: var(--font-size-m);
		border-bottom:2px solid #ffffff;
		color-scheme:dark;
		padding: 0.5em 0 0.25em;
		width:100%;
		display:block;
		outline:none;
		border-radius:0px;
		max-width: var(--input-width);
		cursor: pointer;
		margin: var(--spacing) auto 0;
		text-align: left;
	}
.payout-date__info-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #ffffff;
		box-shadow: var(--box-shadow--4-steps);
		border-radius: 2rem;
		padding: var(--padding);
	}
.payout-date__info-box button {
			color: var(--color-blue);
		}
.payout-date__mock-input {
		padding: 0.5em 0 0.25em;
		border-bottom: 2px solid #ffffff;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		font-size: var(--font-size-m);
		margin: var(--spacing) auto 0;
		max-width: var(--input-width);
	}
.payout-date__mock-input span {
			flex: 1;
		}
.overview__buttons {
		margin-left: auto;
		margin-right: auto;
		max-width: max-content;
		align-items: center;
		display: flex;
		flex-direction: column;
	}
.overview .info-box {
		max-width: 24.25rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #ffffff;
		box-shadow: var(--box-shadow--4-steps);
		border-radius: 2rem;
		padding: var(--spacing);
		margin: 0 auto;
	}
.overview .info-box button {
			color: var(--color-blue);
		}
.overview__success-indicator {
		display: none;
	}
.overview__success-indicator--completed {
			display: flex;
			align-items: center;
			align-self: flex-start;
		}
.overview__success-indicator svg {
			transform: translate(-30%, 0);
		}
.overview__button {
		display: grid;
		grid-template-columns: max-content minmax(0, 250px) max-content;
		grid-gap: var(--spacing--large);
		cursor: pointer;
		margin-left: auto;
		margin-right: auto;
	}
.overview__button--completed {
			--fill: var(--color-cyan);
			--svg-visibility: visible;
		}
.overview__button--disabled {
			opacity: 0.6;
		}
.overview__button .label {
			padding-bottom: var(--spacing--large);
		}
.overview__button .label__headline {
				display: flex;
				align-items: center;
			}
.overview__button .label__headline h3 {
					color: var(--fill, #ffffff);
					margin: 0;
				}
.overview__button svg {
			--svg-color: var(--color-cyan);
		}
.overview__button .progress {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
		}
.overview__button .progress svg {
				--svg-color: #ffffff;
				visibility: var(--svg-visibility, hidden);
				position: absolute;
				width: var(--diameter);
				height: var(--diameter);
				top: 0.25rem;
				z-index: 10;
			}
.overview__button .progress::before {
				background: var(--fill, transparent);
				--diameter: 2rem;
				content: '';
				width: var(--diameter);
				height: var(--diameter);
				display: block;
				border: 2px solid var(--color-cyan);
				border-radius: 100%;
			}
.overview__button .progress::after {
				margin: 4px auto;
				flex: 1;
				content: '';
				display: block;
				border-right: 2px solid var(--color-cyan);
			}
.overview .forward-button {
		width: 100%;
	}
.overview .is-missing {
		width: max-content;
		padding: 2px 6px;
		font-size: var(--font-size-xs);
		border-radius: 4px;
		background-color: var(--error-color);
		margin-top: 8px;
	}
.disclaimer-modal {
	--modal-background-color: var(--color-white);
	--modal-text-color: var(--color-blue--medium);
	--checkbox-stroke-color: var(--color-cyan);
}
.payout-success svg {
		display: block;
		margin: 0 auto;
	}
.payout-success h3 {
		text-align: center;
	}
.payout-summary__mixed-payout {
		padding-bottom: var(--spacing);
	}
.payout-summary .mixed-payout-row {
			display: grid;
			grid-template-columns: repeat(3, minmax(0, 1fr))
		}
.payout-summary .mixed-payout-row:not(:last-child) {
				border-bottom: 1px solid var(--color-blue);
			}
.payout-summary .mixed-payout-row .payout-date {
				white-space: nowrap;
			}
.payout-summary .mixed-payout-row .label,
			.payout-summary .mixed-payout-row .value {
				padding: var(--spacing--small);
			}
.payout-summary .mixed-payout-row .value {
				text-align: right;
			}
.account-blocks {
	text-align: left;
	--chevron-col-width: 40px;
	--table-columns: 3fr 2fr;
	max-width: 1200px;
	margin: 0 auto;
}
.breakpoint-large .account-blocks {
		--border-right: 1px solid var(--color-cyan);
	}
.account-blocks__switch {
		padding: 0 var(--spacing);
	}
.breakpoint-large .account-blocks__switch {
			display: none;
		}
.account-blocks__intro {
		text-align: left;
		margin-bottom: var(--spacing);
	}
.account-blocks .payout-info {
		margin: 0 0 2em;
	}
.account-blocks .payout-info .disclaimer-ausfinanzierung {
			font-style: italic;
		}
/* override child component styles */
.account-blocks .accordion__head {
		border-bottom: 1px solid var(--color-cyan);
	}
.account-blocks .accordion__head .icon-slot {
			width: var(--chevron-col-width);
			min-width: var(--chevron-col-width);
		}
.account-blocks .accordion__content {
		text-align: right;
		background-color: var(--color-black-transparent);
	}
/* end override */
.account-blocks__table-description-header {
		margin-bottom: var(--spacing);
	}
.account-blocks__table-description-header h4 {
			display: none;
		}
.breakpoint-large .account-blocks__table-description-header {
			display: grid;
			grid-template-columns: var(--table-columns);
			padding-right: var(--chevron-col-width);
			background-color: var(--color-white--transparent);
			margin-bottom: 0;
		}
.breakpoint-large .account-blocks__table-description-header h4 {
				display: inline;
				font-family: var(--font-alternative-medium);
			}
.account-blocks__table-description-today,
	.account-blocks__table-description-pension {
		padding: var(--spacing--small) var(--spacing);
		display: grid;
	}
.breakpoint-large .account-blocks__table-description-today, .breakpoint-large .account-blocks__table-description-pension {
			text-align: right;
			padding-bottom: var(--spacing);
		}
.account-blocks__table-description-today {
		border-right: var(--border-right);
	}
.account-blocks__table-description-pension {
		text-align: left;
	}
.account-blocks__table-header {
		display: grid;
		grid-template-columns: var(--table-columns);
		padding-right: var(--chevron-col-width);
		background-color: var(--color-black-transparent);
	}
.account-blocks__table-header h4 {
			font-family: var(--font-family-alternative--medium);
		}
.account-blocks__table-header-today {
		display: grid;
		grid-template-columns: var(--table-columns);
		align-items: center;
	}
.account-blocks__table-header-today,
	.account-blocks__table-header-pension {
		padding: var(--spacing--small) var(--spacing);
		text-align: right;
	}
/* table header row */
.account-blocks__parent-row {
		display: grid;
		grid-template-columns: var(--table-columns);
		font-size: var(--font-size-m);
		font-family: var(--font-alternative-light);
	}
.account-blocks__today-parent,
	.account-blocks__pension-parent {
		padding: var(--spacing);
		align-items: end;
	}
.account-blocks__today-parent {
		display: grid;
		grid-template-columns: var(--table-columns);
		border-right: var(--border-right);
	}
.account-blocks__block-label {
		text-align: left;
	}
.account-blocks__block-amount,
	.account-blocks__pension-parent {
		text-align: right;
	}
/* table child row */
.account-blocks__child-row {
		display: grid;
		grid-template-columns: var(--table-columns);
		width: calc(100% - var(--chevron-col-width));
	}
.account-blocks__today-child {
		display: grid;
		grid-template-columns: var(--table-columns);
		border-right: var(--border-right);
	}
.account-blocks__today-child,
	.account-blocks__pension-child {
		padding: var(--spacing);
		align-items: end;
	}
.account-blocks__sum {
		display: grid;
		grid-template-columns: var(--table-columns);
		padding-right: var(--chevron-col-width);
		background-color: var(--color-cyan);
	}
.account-blocks__sum .h4 {
			font-family: var(--font-alternative-medium);
		}
.account-blocks__today-sum,
	.account-blocks__pension-sum {
		padding: var(--spacing);
		text-align: right;
	}
.account-blocks__today-sum {
		display: grid;
		grid-template-columns: var(--table-columns);
	}
.account-blocks .disclaimer {
		margin-top: var(--spacing);
		padding: 0 var(--spacing);
		text-align: left;
	}
/* toggle classes */
.account-blocks--today .account-blocks__today-parent {
			grid-column: 1/-1;
		}
.breakpoint-large .account-blocks--today .account-blocks__today-parent {
				grid-column: inherit;
			}
.account-blocks--today .account-blocks__today-child {
			grid-column: 1/-1;
		}
.breakpoint-large .account-blocks--today .account-blocks__today-child {
				grid-column: inherit;
			}
.account-blocks--today .hide-if-today {
			display: none;
		}
.breakpoint-large .account-blocks--today .hide-if-today {
				display: inherit;
			}
.account-blocks--today .account-blocks__today-sum {
			grid-column: 1 / -1;
		}
.breakpoint-large .account-blocks--today .account-blocks__today-sum {
				grid-column: inherit;
			}
.account-blocks--pensionAgeOrLeftWithEntitlement .hide-if-pension {
			display: none;
		}
.breakpoint-large .account-blocks--pensionAgeOrLeftWithEntitlement .hide-if-pension {
				display: inherit;
			}
.account-blocks--pensionAgeOrLeftWithEntitlement .account-blocks__today-parent .account-blocks__block-label {
				grid-column: 1 / -1;
			}
.breakpoint-large .account-blocks--pensionAgeOrLeftWithEntitlement .account-blocks__today-parent .account-blocks__block-label {
					grid-column: inherit;
				}
.account-blocks--pensionAgeOrLeftWithEntitlement .account-blocks__today-child .account-blocks__block-label {
				grid-column: 1 / -1;
			}
.breakpoint-large .account-blocks--pensionAgeOrLeftWithEntitlement .account-blocks__today-child .account-blocks__block-label {
					grid-column: inherit;
				}
.account-blocks--payout div {
			border-right: 1px solid transparent;
		}
.account-blocks--payout .hide-if-pension {
			display: none;
		}
.breakpoint-large .account-blocks--payout .hide-if-pension {
				display: none;
			}
.payout-range-slider__detail {
		text-align:center;
		margin-top:calc(var(--spacing) * -1);
		margin-bottom:var(--spacing);
	}
.payout-range-slider__detail--pension {
			color: var(--color-grey--dark);
		}
.payout-range-slider__detail--instalments10Years {
			color: var(--color-cyan);
		}
.mixed-payout-preselection .disabled {
		opacity:0.333;
		pointer-events:none;
	}
.upload .progress-bar {
		width: calc(100% - var(--spacing) * 2);
		max-width: 350px;
		height: 5px;
		background-color: var(--color-blue--dark);
		position: relative;
		border-radius: 99rem;
		margin: 0 auto var(--spacing);
		z-index: 100
	}
.upload .progress-bar::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			transform-origin: left;
			transform: scaleX(var(--progress, 0));
			background: var(--color-cyan);
			width: 100%;
			height: 100%;
			transition: var(--transition);
			transitionproperty: transform;
		}
.upload button {
		margin-top: var(--spacing--large);
		width: 100%;
		margin-bottom: 0;
	}
.payout-documents__status {
		margin-top: var(--spacing)
	}
.payout-documents__status::before {
			content: '';
			display: block;
			width: calc(100% + var(--spacing--small) * 2);
			left: calc(var(--spacing--small) * -1);
			border-top: 1px solid var(--color-cyan);
		}
.payout-documents__status .headline {
			margin-bottom: 0;
		}
.payout-documents__status .is-missing {
			width: max-content;
			padding: 2px 6px;
			font-size: var(--font-size-xs);
			border-radius: 4px;
			background-color: var(--error-color);
		}
.payout-documents-modal-content .select-with-background {
			margin-top: var(--spacing--large);
		}
.payout-documents-modal-content .hidden-input {
			display: none;
		}
.payout-documents-modal-content .upload-button {
			position: sticky;
			bottom: 0;
			width: 100%;
			margin-top: var(--spacing--large);
			margin-bottom: 0;
		}
.payout-documents-modal-content .add-page {
			text-align: left;
			margin-right: auto;
			margin-left: 1.5rem;
		}
.payout-documents-modal-content .delete {
			width: max-content;
			cursor: pointer;
		}
.hidden-input {
	display: none;
}
.payout-document-entry {
	display: grid;
	grid-template-columns: 2fr 3fr;
	grid-gap: var(--spacing);
	padding: var(--spacing);
}
.payout-document-entry__loading-spinner {
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80px;
		height: 80px
	}
.payout-document-entry__loading-spinner::after {
			content: ' ';
			display: block;
			width: 64px;
			height: 64px;
			margin: 8px;
			border-radius: 50%;
			border: 3px solid var(--color-cyan);
			border-color: var(--color-cyan) transparent var(--color-cyan) transparent;
			animation: lds-dual-ring 1.8s linear infinite;
		}
.payout-document-entry .is-threat {
		width: max-content;
		padding: 2px 6px;
		font-size: var(--font-size-xs);
		border-radius: 4px;
		background-color: var(--error-color);
	}
.payout-document-entry input {
		display: none;
	}
.payout-document-entry .delete {
		width: max-content;
		cursor: pointer;
	}
.payout-document-entry__image {
		width: 100%;
		padding-bottom: 140%;
		position: relative;
		background-color: #f8f8f8;
	}
.payout-document-entry__image--has-thumbnail {
			background-color:transparent;
		}
.payout-document-entry__image .sophos-mock {
			position: absolute;
			width: 100%;
			height: 100%;
			padding: var(--spacing);
			overflow:hidden;
		}
.payout-document-entry__image .sophos-mock span {
				display: block;
				color: var(--color-grey);
				border-top: 4px solid currentcolor;
				width: 100%;
				margin-bottom: var(--spacing--small)
			}
.payout-document-entry__image .sophos-mock span:nth-child(1) {
					width: 50%;
				}
.payout-document-entry__image .sophos-mock span:nth-child(2) {
					width: 30%;
				}
.payout-document-entry__image .sophos-mock span:nth-child(3) {
					margin-top:var(--spacing--large);
					width: 33%;
				}
.payout-document-entry__image .sophos-mock span:nth-child(4) {
		
					width: 100%;
				}
.payout-document-entry__image .sophos-mock span:nth-child(6) {
					width: 90%;
				}
.payout-document-entry__image .sophos-mock span:last-child {
					margin-top:var(--spacing);
					width: 20%;
				}
.payout-document-entry__image .pdf-preview {
			--svg-color: var(--color-cyan);
			position: absolute;
			width: 100%;
			height: 100%;
			display: grid;
			align-items: center;
			justify-items: center;
		}
.payout-document-entry__image .pdf-preview svg {
				align-self: end;
			}
.payout-document-entry__image .pdf-preview h4 {
				color: var(--color-cyan);
				align-self: start;
				margin: 0;
			}
.payout-document-entry__image img {
			position: absolute;
			object-fit: contain;
			object-position: top;
			width: 100%;
			height: 100%;
		}
.payout-document-entry__meta {
		text-align: left;
		display: flex;
		flex-direction: column;
	}
.payout-document-entry__meta .delete {
			margin-top: auto;
		}
.file-upload-summary .payout-document-entry {
		padding: var(--spacing) var(--spacing) var(--spacing) 0;
	}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.payout-benefit-type .radiobutton--disabled {
		opacity:1!important;

		
	}
.payout-benefit-type .radiobutton--disabled .input__pseudo-radiobutton svg, .payout-benefit-type .radiobutton--disabled .main-label, .payout-benefit-type .radiobutton--disabled .hidden-hint  {
			opacity:0.2;
		
		}
.main {
	display: flex;
	flex-direction: row;
	margin-top: var(--header-height);
	padding: var(--spacing);
	min-height: var(--main-height);
	max-height: var(--main-height);
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}
.breakpoint-medium .main {
		max-width: calc(100% - var(--navigation-width--static));
		margin-left: var(--navigation-width--static);
	}
.main > * {
		flex: 1;
		height: 100%;
		padding-bottom: var(--spacing);
	}
.login .error:not(.error--visible) {
		display: none;
	}
.login__video-tutorial {
		margin: 0 auto;
		max-width: var(--input-width);
	}
.deferred-compensation__success-icon {
		width: 30em;
		max-width: 50vw;
		max-height: 50vh;
		--checkbox-stroke-width: 6px;
		--checkbox-stroke-color: var(--color-cyan);
		--checkbox-delay: 500ms;
		--checkbox-duration: 3s;
		--checkbox-timing-function: var(--ease-in-out-quad);
		/* TODO: use dynamically calculated path length here */
		--checkbox-path-length: 10000;
		--checkmark-visibility-start: 0;
	}
.deferred-compensation__success-icon circle,
		.deferred-compensation__success-icon polyline {
			stroke-dashoffset: var(--checkbox-path-length);
			animation-name: checkmarkAnimation;
			animation-delay: var(--checkbox-delay);
			animation-duration: var(--checkbox-duration);
			animation-timing-function: var(--checkbox-timing-function);
			animation-fill-mode: forwards;
			stroke-dasharray: var(--checkbox-path-length);
		}
.deferred-compensation__success-icon polyline {
			animation-delay: calc(var(--checkbox-delay) * 2.5);
			stroke-linejoin: round;
			stroke-linecap: round;
		}
@keyframes checkmarkAnimation {
	from {
		stroke-dashoffset: var(--checkbox-path-length);
	}

	to {
		stroke-dashoffset: 0;
	}
}
.fund-composition {
	--accordion-level-0-color: #053e62;
	--accordion-level-1-color: #002134;
}
.fund-composition .sunburst-chart {
		position: relative;
		max-width: var(--sunburst-chart-max-width);
		margin: 0 auto;
	}
.fund-composition .sunburst-chart__label {
			position: absolute;
			text-align: center;
			transform: translate3d(-50%, -50%, 0);
			max-width: 10rem;
			top: 50%;
			left: 50%;
		}
.fund-composition .sunburst-chart .default-label {
			display: block;
		}
.fund-composition__accordion {
		width: calc(100% + (2 * var(--spacing)));
		margin-left: calc(var(--spacing) * -1);
		margin-bottom: var(--accordion-level-height);
	}
.fund-composition .data-row {
		display: flex;
		justify-content: space-between;
	}
.fund-composition .data-row__label {
			padding-right: 10px;
		}
.fund-composition .data-row:not([data-level='0']) {
			padding: 18px
		}
.fund-composition .data-row:not([data-level='0']).data-row--accordion {
				padding-right: 0;
			}
.fund-composition .data-row:not([data-level='0']) .data-row__label,
			.fund-composition .data-row:not([data-level='0']) .data-row__amount {
				opacity: 0.333;
			}
.fund-composition .data-row:not([data-level='0']).data-row--highlighted .data-row__label,
				.fund-composition .data-row:not([data-level='0']).data-row--highlighted .data-row__amount {
					opacity: 1;
				}
.fund-composition .data-row--accordion .data-row__amount {
				text-align: right;
			}
.fund-composition .data-row--accordion[data-level='0'] {
				flex-direction: row-reverse;
				align-items: center;
			}
.fund-composition .data-row--accordion[data-level='0'] > .data-row__label,
				.fund-composition .data-row--accordion[data-level='0'] > .data-row__amount {
					padding-left: var(--spacing);
				}
.fund-composition .data-row--accordion[data-level='0'] .data-row__label {
					flex: 1;
					text-align: left;
					font-family: var(--font-system);
					font-weight: var(--font-system--bold);
				}
.fund-composition .data-row--accordion[data-level='0'] .data-row__amount {
					flex: 1;
					font-size: 40px;
					text-align: right;
					position: relative;
					padding: 10px 0 10px var(--spacing);
					min-width: 150px
				}
.fund-composition .data-row--accordion[data-level='0'] .data-row__amount::after {
						content: '';
						background-color: var(--accordion-color);
						position: absolute;
						bottom: -4px;
						left: 0;
						height: 7px;
						border-radius: 0 1rem 1rem 0;
						width: 100%;
						z-index: 10;
					}
.fund-composition .data-row:not(.data-row--accordion) {
			padding-right: calc(var(--min-touch-width) * 2);
		}
.fund-composition .accordion {
		transition: 0.4s ease-in-out;
		transition-property: transform, opacity;
		position: relative
	}
.fund-composition .accordion.accordion:not([data-level='0']) .icon-slot {
				opacity: 1;
			}
.fund-composition .accordion.accordion:not([data-level='0']) .accordion__head {
				position: relative;
				z-index: 10;
			}
.fund-composition .accordion.accordion--highlighted:not([data-level='0']) .icon-slot {
				opacity: 1;
			}
.fund-composition .accordion--open .accordion__content::before {
					transform-origin: top;
					transform: scale(1);
				}
/**
				* TODO: Use a proper loop
				*/
.fund-composition .accordion--open .accordion__content > .content-wrapper > .data-row > .data-row__label,
						.fund-composition .accordion--open .accordion__content > .content-wrapper > .data-row > .data-row__amount,
						.fund-composition .accordion--open .accordion__content > .content-wrapper > .accordion > .accordion__head > .head-slot > .data-row > .data-row__label,
						.fund-composition .accordion--open .accordion__content > .content-wrapper > .accordion > .accordion__head > .head-slot > .data-row > .data-row__amount {
							transform: translateY(0);
						}
.fund-composition .accordion--open ~ .accordion {
				transform: translateY(var(--accordion-level-height, 0));
			}
.fund-composition .accordion__content {
			position: absolute;
			width: 100%;
		}
.fund-composition .accordion__content .content-wrapper > .data-row > .data-row__label,
					.fund-composition .accordion__content .content-wrapper > .data-row > .data-row__amount {
						transform: translateY(calc(var(--spacing--small) * -1));
						transition: 0.4s ease-out;
						transition-property: transform, opacity;
					}
.fund-composition .accordion__content::before {
				content: '';
				position: absolute;
				width: 100%;
				top: 0;
				height: calc(var(--accordion-level-height) + var(--accordion-content-height));
				left: 0;
				background-color: var(--accordion-level-0-color);
				opacity: 1;
				transition: 0.4s ease-in-out;
				transition-property: transform, height;
				transform-origin: top;
				transform: scaleY(0);
			}
.fund-composition .accordion__content {

			/**
			 * Fallback for MS Edge which does not support inline updates of custom properties 
			 */
		}
@supports (-ms-ime-align: auto) {

		.fund-composition .accordion__content {
				background-color: var(--accordion-level-1-color);
		}

				.fund-composition .accordion__content .accordion__head {
					background-color: var(--accordion-level-0-color);
				}

				.fund-composition .accordion__content::before {
					content: none;
				}
			}
.fund-composition .accordion--highlighted .icon-slot {
				opacity: 1;
			}
.fund-composition .accordion--highlighted .data-row .data-row__label,
				.fund-composition .accordion--highlighted .data-row .data-row__amount {
					opacity: 1;
				}
.fund-composition .accordion[data-level='0'] > .accordion__head {
				margin-top: var(--spacing);
				position: relative;
				font-family: var(--font-alternative-light)
			}
.fund-composition .accordion[data-level='0'] > .accordion__head::before {
					content: '';
					position: absolute;
					height: 1px;
					width: 100%;
					bottom: 0;
					left: 0;
					background-color: var(--color-blue);
					opacity: 0.333;
				}
.fund-composition .accordion[data-level='1'] .accordion__content .data-row__label {
					padding-left: var(--spacing);
				}
.fund-composition .accordion[data-level='1'] .accordion__content::before {
					background-color: var(--accordion-level-1-color);
				}
.fund-composition .accordion[data-level='2'] .accordion__content .data-row__label {
					padding-left: calc(var(--spacing) * 2);
				}
.fund-composition .accordion[data-level='3'] .accordion__content .data-row__label {
					padding-left: calc(var(--spacing) * 3);
				}
.dashboard {
	--column-gap: var(--spacing--small);
	--dashboard-columns: 4;
	max-width: 100%;
	display: grid;
	grid-column-gap: var(--column-gap);
	grid-row-gap: var(--column-gap);
	grid-template-columns: repeat(var(--dashboard-columns), 1fr);
	grid-auto-rows: min-content;
	grid-auto-flow: row dense;
}
.breakpoint-medium .dashboard {
		--column-gap: var(--spacing);
		--dashboard-columns: 4;
	}
.breakpoint-large .dashboard {
		--dashboard-columns: 6;
	}
.dashboard__title {
		grid-column: span var(--dashboard-columns);
		margin-bottom: 0;
	}
.conversions {
	display: grid;
	grid-template-areas: 'title' 'chart';
	grid-template-rows: min-content minmax(0, 100%);
}
.conversions__title {
		grid-area: title;
	}
.conversions__chart {
		grid-area: chart;
		align-self: center;
		justify-self: center;
		place-self: center;
	}
.conversions__chart--no-drilling .bar-chart__chart,
			.conversions__chart--no-drilling .bar-chart__bar {
				cursor: default;
			}
.conversions__chart--no-drilling .bar-chart__label {
				cursor: default;
			}
.conversions__chart--no-drilling .bar-chart__label circle,
				.conversions__chart--no-drilling .bar-chart__label polyline {
					opacity: 0;
				}
.indices-comparison {
	margin-top: calc(var(--spacing) * -1);
	display: grid;
	grid-template-rows: auto auto 1fr min-content;
}
.indices-comparison__figures {
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: var(--spacing--small);
		align-items: center;
		width: calc(100% + 2 * var(--spacing));
		margin-left: calc(var(--spacing) * -1);
		padding: var(--spacing) 0;
	}
.indices-comparison__figures .select-input {
			margin-top: var(--spacing--small);
			font-size: var(--font-size-s);
		}
.indices-comparison__figures .select-input__value {
				font-weight: var(--font-system-bold);
			}
.indices-comparison__figures::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
			width: 100vw;
			background-color: var(--color-white);
			opacity: 0.2;
			z-index: -1;
		}
.breakpoint-medium .indices-comparison__figures::before {
				width: 100%;
				left: 0;
				right: 0;
				margin-left: 0;
				margin-right: 0;
				border-radius: var(--spacing);
			}
.indices-comparison__graph {
		margin-top: var(--spacing);
		margin-left: calc(var(--spacing) * -1);
		width: calc(100% + 2 * var(--spacing));
	}
.indices-comparison__graph > div {
			height: 100%;
		}
.pension-assets {
	margin-top: calc(var(--spacing) * -1);
	height: inherit
}
.pension-assets > * {
		flex-shrink: 0;
	}
.pension-assets__figures {
		position: relative;
		display: grid;
		grid-template-columns: 2fr 4fr;
		grid-row-gap: var(--spacing--small);
		grid-column-gap: var(--spacing);
		align-items: center;
		width: calc(100% + 2 * var(--spacing));
		margin-left: calc(var(--spacing) * -1);
		padding: var(--spacing) 0;
		z-index: 0;
	}
.pension-assets__figures .big-color-figure__figure {
			font-size: var(--font-size-m);
		}
.pension-assets__figures .accordion__content::after,
		.pension-assets__figures .accordion__content::before,
		.pension-assets__figures::after,
		.pension-assets__figures::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
			width: 100vw;
			background-color: var(--color-blue--faded);
			z-index: -1;
			transition: transform var(--transition);
		}
.breakpoint-medium .pension-assets__figures .accordion__content::after, .breakpoint-medium .pension-assets__figures .accordion__content::before, .breakpoint-medium .pension-assets__figures::after, .breakpoint-medium .pension-assets__figures::before {
				width: 100%;
				left: 0;
				right: 0;
				margin-left: 0;
				margin-right: 0;
				border-radius: var(--spacing);
			}
.pension-assets__figures .accordion__head {
			position: relative;
			z-index: 10;
		}
.pension-assets__figures .accordion__content {
			transform: translateY(calc(-1 * var(--accordion-content-height)));
			z-index: 0
		}
.breakpoint-medium .pension-assets__figures .accordion__content::before, .breakpoint-medium .pension-assets__figures .accordion__content::after {
					border-radius: 0 0 var(--spacing) var(--spacing);
				}
.pension-assets.accordion-2-open .pension-assets__figures .accordion__content {
			transform: translateY(0);
		}
.pension-assets.accordion-2-open .pension-assets__figures ~ * {
			transform: translateY(calc(var(--accordion-content-height-2, 0)));
		}
.pension-assets.accordion-1-open.accordion-2-open .pension-assets__figures ~ * {
			transform: translateY(calc(var(--accordion-content-height) + var(--accordion-content-height-2, 0)));
		}
.pension-assets__own-contributions {
		grid-column: 1 / 3;
	}
.pension-assets__own-contributions .accordion__content {
			padding: var(--spacing) 0;
		}
.pension-assets__own-contributions .accordion__content .content-wrapper {
				display: grid;
				grid-template-columns: 2fr 4fr;
				grid-row-gap: var(--spacing--small);
				grid-column-gap: var(--spacing);
				justify-content: center;
				align-items: center;
				color: var(--color-black);
			}
.pension-assets__own-contributions .accordion__content .content-wrapper .sum {
					text-align: right;
					font-size: var(--font-size-s);
					font-family: var(--font-family-alternative--light);
				}
.pension-assets__own-contributions .accordion__head {
			display: grid;
			grid-template-columns: 2fr 4fr;
			grid-row-gap: var(--spacing--small);
			grid-column-gap: var(--spacing);
		}
.pension-assets__own-contributions .accordion__head .head-slot {
				display: contents;
			}
.pension-assets__own-contributions .accordion__head .head-slot .big-color-figure {
					grid-row: 1;
					grid-column: 0 / 2;
				}
.pension-assets__own-contributions .accordion__head .head-slot .label {
					grid-row: 1;
					grid-column: 2 / 3;
				}
.pension-assets__own-contributions .accordion__head .icon-slot {
				grid-row: 1;
				grid-column: 2 / 3;
				margin-left: auto;
			}
.pension-assets__graph {
		margin-top: var(--spacing);
		transition: var(--transition-duration) var(--ease-out-cubic);
		transition-property: transform;
	}
.pension-assets__graph > div {
			height: 100%;
		}
.profile {
	--input-width: 100%;
	display: grid;
	grid-template-areas: 'fields' 'readonly-fields' 'error' 'submit';
	grid-template-rows: min-content min-content min-content min-content;
}
.profile__form {
		display: contents;
	}
.profile__fields {
		grid-area: fields;
	}
.profile__readonly-fields {
		grid-area: readonly-fields;
	}
.profile__error {
		grid-area: error;
	}
.profile__submit,
	.profile__unlock {
		position: sticky;
		bottom: var(--spacing);
		margin-top: var(--spacing);
		grid-area: submit;
	}
.profile__submit--locked {
		display: none;
	}
.profile__unlock--unlocked {
		display: none;
	}
.profile .input {
		max-width: 100%;
		width: 100;
	}
.profile .input--nachname .input__icon {
			width: 6rem;
			height: 6rem;
		}
.download-dse {
	max-width: max-content;
	text-decoration:none;
		font-family: var(--font-family-button);
}
.profile .input {
		max-width: 100%;
		width: 100%;
	}
.contact {
	display: grid;
	grid-row-gap: var(--spacing);
	grid-column-gap: var(--spacing--large);
}
.contact__icon {
		--svg-color: var(--color-cyan);
	}
.breakpoint-medium .contact {
		grid-template-areas:
			'header header'
			'phone mail'
			'footer footer';
	}
.breakpoint-medium .contact__header {
			grid-area: header;
		}
.breakpoint-medium .contact__phone {
			grid-area: phone;
		}
.breakpoint-medium .contact__email {
			grid-area: mail;
		}
.breakpoint-medium .contact button {
			width: 100%;
		}
.breakpoint-medium .contact__footer {
			grid-area: footer;
		}
.breakpoint-medium .contact__footer p {
				margin-bottom: var(--spacing);
			}
.terminate-digital-participation {
	display: grid;
	grid-row-gap: var(--spacing);
	grid-column-gap: var(--spacing--large);
}
.terminate-digital-participation__icon {
		--svg-color: var(--color-cyan);
	}
.breakpoint-medium .terminate-digital-participation {
		grid-template-areas:
			'header header'
			'phone mail'
			'footer footer';
	}
.breakpoint-medium .terminate-digital-participation__header {
			grid-area: header;
		}
.breakpoint-medium .terminate-digital-participation__phone {
			grid-area: phone;
		}
.breakpoint-medium .terminate-digital-participation__email {
			grid-area: mail;
		}
.breakpoint-medium .terminate-digital-participation button {
			width: 100%;
		}
.breakpoint-medium .terminate-digital-participation__footer {
			grid-area: footer;
		}
.breakpoint-medium .terminate-digital-participation__footer p {
				margin-bottom: var(--spacing);
			}
.faq table {
		margin: var(--spacing--small) 0;
		border-collapse: collapse
	}
.faq table th {
			text-align: left;
		}
.faq table th,
		.faq table td {
			padding-left: 0
		}
.faq table th:not(:last-child), .faq table td:not(:last-child) {
				padding-right: var(--spacing);
			}
.faq .accordion__content {
		padding-right: calc(var(--spacing) * 3);
	}
.faq__quick-links button {
			margin-left: 0;
			margin-right: var(--spacing--small);
		}
.risk-benefit__preview-graph {
		margin-top: var(--spacing);
		margin-left: calc(var(--spacing) * -1);
		width: calc(100% + 2 * var(--spacing));
		min-height: 400px;
	}
.risk-benefit__preview-graph > div {
			height: 100%;
		}
.risk-benefit__preview-graph .bar-chart__chart {
				flex-direction: column;
			}
.risk-benefit__preview-graph .bar-chart__label--x {
				text-transform: none;
			}
.risk-benefit__list li {
			display: flex;
			padding: var(--spacing--small) 0
		}
.risk-benefit__list li::before {
				content: '●';
				color: var(--color-cyan);
				font-size: 1.5em;
				line-height: 0.7em;
				margin-right: var(--spacing--small);
			}
.risk-benefit__partner-form-summary {
		display: grid;
		grid-template-columns: max-content minmax(0, 100%);
		grid-gap: 0 var(--spacing);
		gap: 0 var(--spacing);
	}
.risk-preview {
	min-height: 400px;
}
.header {
	position: fixed;
	display: grid;
	top: 0;
	left: 0;
	right: 0;
	height: var(--header-height);
	grid-template-columns: 15vw minmax(0, 100%) 15vw;
	grid-template-areas: 'left center right';
	justify-items: center;
	align-items: center;
	font-size: var(--font-size-m);
	z-index: 1000;
}
.breakpoint-medium .header {
		grid-template-columns: var(--navigation-width--static) minmax(0, 100%) 0;
	}
.header::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--gradient-vertical-to-transparent);
		z-index: -1
	}
@supports (pointer-events: none) {
.header::before {
			pointer-events: none;
			height: 100%
	}
		}
@supports (padding: env(safe-area-inset-top)) {
.header {
		padding-top: env(safe-area-inset-top)
}
	}
.header .back-button {
		grid-area: left;
		align-self: stretch;
		justify-self: stretch
	}
.header .back-button:not(.back-button--hidden) ~.lang-switch {
				display: none;
			}
.breakpoint-medium .header .back-button:not(.back-button--hidden) ~.lang-switch {
					display: block;
				}
.header .navigation {
		grid-area: right;
		align-self: stretch;
		justify-self: stretch;
	}
.header .navigation-title {
		grid-area: center;
	}
.header .loading-spinner {
		grid-area: right;
		display: none;
	}
.header .lang-switch {
		display: grid;
		grid-template-columns: 1fr 1fr;
		z-index: 1000;
		align-self: center;
		justify-self: start;
		margin-right: var(--spacing);
		grid-row: 1/2;
		grid-column: 1/2;
		transform: scale(0.75);
	}
.breakpoint-medium .header .lang-switch {
			justify-self: end;
			grid-area: right;
			left: auto;
		}
.header .lang-switch span {
			padding: 0 var(--spacing--small);
			cursor: pointer;
			text-transform: uppercase
		}
.header .lang-switch span:not(:last-child) {
				border-right: 1px solid var(--color-white);
			}
/* .header-backdrop {
	position: fixed;
	z-index: 900;
	top: 0;
	left: 0;
	height: var(--header-height);
	width: 100%;
	backdrop-filter: blur(10px);
} */
.navigation {
	display: flex;
	font-family: var(--font-alternative-light);
}
.navigation__toggle {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: relative;
		z-index: 120
	}
.navigation__toggle:hover ~ .navigation__items {
				will-change: transform, box-shadow;
			}
.navigation__toggle:hover ~ .navigation__background-toggle {
				will-change: backdrop-filter;
			}
.navigation .navigation__hamburger {
		--hamburger-bar-height: 2px;
		--hamburger-bar-width: 26px;
		--hamburger-bar-color: var(--color-cyan);
		--hamburger-bar-transition-duration: 0.3s;
		height: var(--hamburger-bar-height);
		width: var(--hamburger-bar-width);
		background-color: var(--hamburger-bar-color);
		position: relative;
		border-radius: 1rem;
		transition: var(--hamburger-bar-transition-duration);
		transition-property: transform;
	}
.breakpoint-medium .navigation .navigation__hamburger {
			display: none;
		}
.navigation .navigation__hamburger::after,
		.navigation .navigation__hamburger::before {
			transition: var(--hamburger-bar-transition-duration);
			transition-property: transform, opacity;
			content: '';
			border-radius: 1rem;
			position: absolute;
			width: 100%;
			height: var(--hamburger-bar-height);
			background-color: var(--hamburger-bar-color);
			transform-origin: center;
		}
.navigation .navigation__hamburger::after {
			transform: translateY(0.5rem);
		}
.navigation .navigation__hamburger::before {
			transform: translateY(-0.5rem) scale(1);
		}
.navigation .navigation__hamburger--close {
			transform: rotate(45deg)
		}
.navigation .navigation__hamburger--close::after {
				opacity: 0;
				transform: translateY(0) rotate(-90deg);
			}
.navigation .navigation__hamburger--close::before {
				transform: translateY(0) rotate(-90deg);
			}
.navigation__background-toggle {
		pointer-events: none;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		max-height: 100vh;
		z-index: 10;
		-webkit-backdrop-filter: blur(0);
		        backdrop-filter: blur(0);
		transition: -webkit-backdrop-filter var(--transition-duration) var(--ease-out-cubic);
		transition: backdrop-filter var(--transition-duration) var(--ease-out-cubic);
		transition: backdrop-filter var(--transition-duration) var(--ease-out-cubic), -webkit-backdrop-filter var(--transition-duration) var(--ease-out-cubic)
	}
.navigation__background-toggle:hover {
			will-change: backdrop-filter;
		}
.navigation__background-toggle:hover ~ .navigation__items {
				will-change: transform, box-shadow;
			}
.breakpoint-medium .navigation__background-toggle {
			display: none !important;
		}
.navigation__toggle-checkbox:checked ~ .navigation__items {
				transform: translateX(0);
				box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2), -2px 2px 2px rgba(0, 0, 0, 0.2),
					-4px 4px 4px rgba(0, 0, 0, 0.2), -8px 8px 8px rgba(0, 0, 0, 0.2), -16px 16px 16px rgba(0, 0, 0, 0.2);
			}
.navigation__toggle-checkbox:checked ~ .navigation__background-toggle {
				pointer-events: all;
				display: block;
				-webkit-backdrop-filter: blur(10px);
				        backdrop-filter: blur(10px);
			}
.navigation__items {
		position: fixed;
		display: flex;
		flex-direction: column;
		padding-top: var(--header-height);
		overflow: auto;
		overscroll-behavior-y: none;
		top: 0;
		right: 0;
		height: 100%;
		max-height: 100vh;
		min-width: 60vw;
		max-width: 100vw;
		box-shadow: none;
		transform: translateX(100%);
		transition: transform var(--transition-duration) var(--ease-out-cubic);
		z-index: 110;
		background-color: var(--color-blue--super-dark);
		-webkit-overflow-scrolling: touch;
	}
.breakpoint-medium .navigation__items {
			transform: translateX(0);
			left: 0;
			right: auto;
			width: var(--navigation-width--static);
			min-width: var(--navigation-min-width--static);
		}
.navigation__item {
		font-size: var(--font-size-m);
	}
.navigation__item--lang {
			position: absolute;
			top: 0;
		}
.navigation__item:last-child {
			margin-bottom: var(--spacing);
		}
.navigation__item--active {
			--link-color: var(--color-cyan);
		}
.navigation__item--imprint {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			flex-grow: 1;
			flex-shrink: 0;
			font-size: var(--font-size-s);
			padding-top: 0;
			padding-bottom: 0;
		}
.navigation__item--imprint .navigation__link-text {
				justify-content: flex-end
			}
.navigation__item--imprint .navigation__link-text::after {
					content: none;
				}
.navigation__link {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		text-decoration: none;
		padding: 0.5em var(--spacing) 0.5em 1em;
		cursor: pointer;
		background-color: var(--link-color, transparent);
	}
.navigation__icon {
		margin-right: var(--spacing);
	}
.navigation__link-text {
		display: flex;
		flex: 1;
		align-items: center;
		padding: 1em 0;
		position: relative
	}
.navigation__link-text::after {
			content: '';
			position: absolute;
			bottom: -0.5em;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: rgba(255, 255, 255, 0.2);
		}
.navigation-title {
	/* stylelint-disable */
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	/* stylelint-enable */
	word-break: break-word;
	font-family: var(--font-alternative-medium);
	text-align: center;
	font-size: var(--font-size-m);
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
	max-height: 100%;
}
.logo {
	display: grid;
	align-items: center;
	grid-gap: 0.5rem;
	grid-auto-flow: column;
}
.logo img {
		width: 2.25rem;
	}
.back-button {
	position: relative;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: opacity 0.25s var(--ease-in-out-quad);
}
.back-button--hidden {
		pointer-events: none;
		opacity: 0;
	}
.breakpoint-medium .back-button {
		z-index: 120;
		width: var(--header-height);
		margin-left: var(--navigation-width--static);
	}
.PhoneInputCountry {
	grid-area: prefix;
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	padding: 0 2.1ch 0 0;
	margin-right: 0.5em;
	font-size: 1.25rem;
	margin-top: 2px;
}
/* &::after {
		content: '';
		width: 100%;
		position: absolute;
		top: 100%;
		height: 2px;
		background: #ffffff;
	} */
.PhoneInputCountry > svg {
		position: absolute;
		right: 0;
		transform: rotate(90deg) scale(0.5);
	}
.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}
.PhoneInputCountryIconImg {
	width: 1em;
}
/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}
/* Remove default padding */
ul[class],
ol[class] {
	padding: 0;
}
/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}
html {
	font-size: 16px;
}
/* Set core body defaults */
body {
	/* min-height: 100vh;
	scroll-behavior: smooth; */
}
/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
	list-style: none;
}
/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}
/* Make images easier to work with */
img {
	max-width: 100%;
	display: block;
}
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}
:root {
	/* fonts */
	--font-alternative-medium: 'PrometoMedium';
	--font-alternative-light: 'PrometoLight';

	--font-system: 'Verdana', 'Geneva', 'sans-serif';
	--font-system-bold: 700;
	--font-system-regular: 400;
	--font-system-light: 100;

	--inner-width: 800px;

	/* font-sizes */
	--font-size-xxl: 4.375rem;
	--font-size-xl: 3.375rem;
	--font-size-l: 2rem;
	--font-size-m: 1.25rem;
	--font-size-default: 1rem;
	--font-size-s: 0.81rem;
	--font-size-xs: 0.6rem;

	/* for fluid-font-sizes */
	--font-size-xxl--px: 70px;
	--font-size-xl--px: 54px;
	--font-size-l--px: 32px;
	--font-size-m--px: 20px;
	--font-size-default--px: 16px;
	--font-size-s--px: 13px;
	--font-size-xs--px: 10px;

	/* line height */

	--line-height-spaced: 1.333;

	/* element-sizes */
	--input-width: 30rem;
	--min-touch-width: 2.75rem;
	--sunburst-chart-max-width: 24rem;
	--modal-window-max-width: 32rem;

	/* colors */

	--color-blue--faded: rgb(52, 81, 105);
	--color-blue: rgb(17, 121, 191);
	--color-blue--medium: rgb(0, 77, 122);
	--color-blue--dark: rgb(2, 33, 52);
	--color-blue--super-dark: rgb(0, 16, 36);
	--color-black: rgb(0, 0, 0);

	--color-blue--super-dark-slightly-transparent: rgba(0, 16, 36, 0.99);
	--color-blue--super-dark-fully-transparent: rgba(0, 16, 36, 0);

	--color-blue--medium-fully-transparent: rgba(0, 77, 122, 0);

	--color-cyan--transparent: rgb(0, 171, 231, 0.2);
	--color-cyan: rgb(0, 171, 231);
	--color-cyan--medium: rgb(129, 188, 223);
	--color-cyan--light: rgb(192, 222, 239);
	--color-red: rgb(221, 12, 41);
	--color-magenta: rgb(229, 0, 75);
	--color-white: rgb(255, 255, 255);
	--color-black: rgb(0, 0, 0);
	--color-grey--dark: #333333;
	--color-grey: rgb(127, 127, 127);
	--color-grey--light: rgb(191, 191, 191);
	--color-grey--faded: rgb(0, 0, 0);
	--color-white--transparent: rgba(255, 255, 255, 0.15);
	--color-black-transparent: rgba(0, 0, 0, 0.15);
	--color-orange: rgba(242, 121, 53, 1);
	--color-yellow: rgb(255, 192, 0);
	--color-green: rgb(146, 208, 80);
	--color-other-blue: rgb(17, 121, 255);

	--text-color: var(--color-white);
	--line-color: var(--color-white);
	--error-color: var(--color-red);

	--loading-spinner-color: var(--color-white);

	/* gradients */
	--gradient-colors: var(--color-blue--super-dark) 15%, var(--color-blue--medium) 65%, var(--color-blue) 100%;
	--gradient-colors-lesser: var(--color-blue--medium) 0%, var(--color-blue) 100%;
	--gradient-colors-transparent: var(--color-blue--super-dark) 66%,
		var(--color-blue--super-dark-fully-transparent) 100%;
	--gradient-colors--alternative: var(--color-cyan) 0%, rgb(77, 149, 196) 35%, var(--color-magenta) 65%,
		var(--color-red) 100%;
	--gradient-colors--primary-button: var(--color-red) 0%, var(--color-magenta) 100%;
	--gradient-vertical-to-transparent: linear-gradient(to bottom, var(--gradient-colors-transparent));
	--gradient-vertical: linear-gradient(to bottom, var(--gradient-colors));
	--gradient-vertical-lesser: linear-gradient(to bottom, var(--gradient-colors-lesser));
	--gradient-horizontal: linear-gradient(to right, var(--gradient-colors));
	--gradient-vertical--alternative: linear-gradient(to top, var(--gradient-colors--alternative));
	--gradient-horizontal--alternative: linear-gradient(to right, var(--gradient-colors--alternative));

	/* miscellaneous */
	--spacing--large: 3rem;
	--spacing: 1.5rem;
	--spacing--small: 0.5rem;
	--border-radius: 0.5rem;
	--margin: var(--spacing);
	--padding: var(--spacing);
	--header-height: 6.25rem;

	/* default SVG Color */
	--svg-color: #ffffff;

	/* charts */
	--tooltip-background-color: var(--color-blue);

	--pan-button-color: var(--color-white);
	--pension-assets-ghost-bars-color: var(--color-white);
	--pension-assets-chart-grid-color: var(--color-cyan);

	/* snap-slider */
	--snap-slider-button-color: var(--color-cyan);

	/* accordion */
	--accordion-toggle-color: var(--color-white);

	/* error-message */
	--error-message-icon-color: var(--color-red);

	/* submenu-bar */
	--submenu-bar-icon-color: var(--color-cyan);

	/* submenu-bar */
	--hint-icon-color: var(--color-cyan);
}
@supports (padding: env(safe-area-inset-top)) {
:root {
		--header-height: calc(6.25rem + env(safe-area-inset-top));
}
	}
:root {

	--main-height: calc(100vh - var(--header-height));
}
@supports (padding: env(safe-area-inset-top)) {
:root {
		--main-height: calc(100vh - var(--header-height) - env(safe-area-inset-bottom));
}
	}
:root {

	/* transitions */
	--transition-duration: 0.5s;
	--ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	--ease-out-back-less: cubic-bezier(0.175, 0.885, 0.32, 1.05);
	--ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	--ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
	--ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	--ease-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

	--transition: var(--transition-duration);

	/* shadows */
	--box-shadow-bottom--3-steps: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1);
	--box-shadow-bottom--4-steps: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1);
	--box-shadow-bottom--5-steps: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);

	--box-shadow-right--5-steps: 1px 0 1px rgba(0, 0, 0, 0.1), 2px 0 2px rgba(0, 0, 0, 0.1),
		4px 0 4px rgba(0, 0, 0, 0.1), 8px 0 8px rgba(0, 0, 0, 0.1), 16px 0 16px rgba(0, 0, 0, 0.1);

	/* Responsive */

	--fluid-typo-max-width: 320px;
	--fluid-typo-min-width: 1200px;

	--fluid-typo-difference: 880;

	--navigation-width--static: 365px;
	--navigation-min-width--static: 300px;
	--content-max-width: 40rem;
	--content-max-width--narrow: 30rem;
}
@font-face {
    font-family: 'PrometoMedium';
    src: url('fonts/PrometoW04-Medium.woff2') format('woff2'),
        url('fonts/PrometoW04-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'PrometoLight';
    src: url('fonts/PrometoW04-Light.woff2') format('woff2'),
        url('fonts/PrometoW04-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
html {
	font-family: var(--font-system);
	font-weight: 300;
}
.bold {
	font-weight: bold;
}
b.font-family-alternative,
	.font-family-alternative b,
	strong.font-family-alternative,
	.font-family-alternative strong,
	.bold.font-family-alternative,
	.font-family-alternative .bold {
		font-family: var(--font-alternative-medium);
		font-weight: 500;
	}
h1,h2,h3,h4,h5 {
	margin: 1em 0;
	font-family: var(--font-alternative-medium);
	font-weight: normal;
}
p {
	line-height: var(--line-height-spaced);
	font-size: var(--font-size-s)
}
p + p {
		margin-top: var(--spacing--small);
	}
.underline {
	text-decoration:underline;
}
.font-family-alternative--medium {
		font-family: var(--font-alternative-medium);
		font-weight: normal;
	}
.font-family-alternative--light {
		font-family: var(--font-alternative-light);
		font-weight: normal;
	}
.font-family-alternative--light b {
			font-family: var(--font-alternative-medium);
		}
h1,
h2 {
	font-size: var(--font-size-l);
	text-align: center
}
h1.fluid, h2.fluid {
		font-size: calc(
			var(--font-size-m--px) + 12 * ((100vw - var(--fluid-typo-max-width)) / var(--fluid-typo-difference))
		);
	}
h2,
h4,
.h4 {
	font-family: var(--font-alternative-light);
}
h3,
h4,
.h4 {
	font-size: var(--font-size-m);
}
a {
	color: currentColor;
}
li {
	margin-bottom: var(--spacing--small);
}
.text-color-blue {
		color: var(--color-blue);
	}
.text-color-cyan {
		color: var(--color-cyan);
	}
.text-color-red {
		color: var(--color-red);
	}
.text-color-black {
		color: var(--color-black);
	}
.text-color-white {
		color: var(--color-white);
	}
.text-color-grey {
		color: var(--color-grey);
	}
.font-size-xxl {
		font-size: var(--font-size-xxl);
	}
.font-size-xl {
		font-size: var(--font-size-xl);
	}
.font-size-l {
		font-size: var(--font-size-l);
	}
.font-size-m {
		font-size: var(--font-size-m);
	}
.font-size-default {
		font-size: var(--font-size-default);
	}
.font-size-s {
		font-size: var(--font-size-s);
	}
.breakpoint-medium .bp-medium-font-size-xxl {
			font-size: var(--font-size-xxl);
		}
.breakpoint-medium .bp-medium-font-size-xl {
			font-size: var(--font-size-xl);
		}
.breakpoint-medium .bp-medium-font-size-l {
			font-size: var(--font-size-l);
		}
.breakpoint-medium .bp-medium-font-size-m {
			font-size: var(--font-size-m);
		}
.breakpoint-medium .bp-medium-font-size-default {
			font-size: var(--font-size-default);
		}
.breakpoint-medium .bp-medium-font-size-s {
			font-size: var(--font-size-s);
		}
.breakpoint-large .bp-large-font-size-xxl {
			font-size: var(--font-size-xxl);
		}
.breakpoint-large .bp-large-font-size-xl {
			font-size: var(--font-size-xl);
		}
.breakpoint-large .bp-large-font-size-l {
			font-size: var(--font-size-l);
		}
.breakpoint-large .bp-large-font-size-m {
			font-size: var(--font-size-m);
		}
.breakpoint-large .bp-large-font-size-default {
			font-size: var(--font-size-default);
		}
.breakpoint-large .bp-large-font-size-s {
			font-size: var(--font-size-s);
		}
.hr {
	background-color: var(--line-color);
	border: none;
	height: 0.125rem;
	margin: 0.5rem auto;
}
.hr--narrow {
		width: 15vw;
	}
.hr--slim {
		height: 0.0625rem;
	}
.hr--thick {
		height: 0.25rem;
	}
.hr--blue {
		--line-color: var(--color-blue);
	}
html {
	overflow-x: hidden;
	overscroll-behavior: none;
	-webkit-tap-highlight-color: transparent;
	hyphens: auto
}
html::before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		transform: translateZ(0);
		background: var(--gradient-vertical);
		z-index: -1;
	}
body {
	line-height: var(--line-height-spaced);
	font-size: var(--font-size-s)
}
@supports (padding: env(safe-area-inset-bottom)) {
body {
		padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)
}
	}
body * {
		-webkit-user-select: var(--user-select, auto);
		        user-select: var(--user-select, auto);
	}
#root {
	display: contents;
	color: var(--text-color);
}
.fragment {
	display: contents;
}
.flex {
	display: flex;
}
.flex--direction-column {
		flex-direction: column;
	}
.flex--direction-column-reverse {
			flex-direction: column-reverse;
		}
.flex--direction-row {
		flex-direction: row;
	}
.flex--direction-row-reverse {
			flex-direction: row-reverse;
		}
.flex--wrap {
		flex-wrap: wrap;
	}
.flex--justify-content-start {
			justify-content: flex-start;
		}
.flex--justify-content-end {
			justify-content: flex-end;
		}
.flex--justify-content-center {
			justify-content: center;
		}
.flex--justify-content-between {
			justify-content: space-between;
		}
.flex--justify-content-around {
			justify-content: space-around;
		}
.flex--justify-content-evenly {
			justify-content: space-evenly;
		}
.flex--align-items-start {
			align-items: flex-start;
		}
.flex--align-items-end {
			align-items: flex-end;
		}
.flex--align-items-center {
			align-items: center;
		}
.flex--align-items-stretch {
			align-items: stretch;
		}
.flex--align-content-start {
			align-content: flex-start;
		}
.flex--align-content-end {
			align-content: flex-end;
		}
.flex--align-content-center {
			align-content: center;
		}
.flex--align-content-stretch {
			align-content: stretch;
		}
.flex--align-content-between {
			align-content: space-between;
		}
.flex--align-content-around {
			align-content: space-around;
		}
.flex--align-self-start {
			align-self: flex-start;
		}
.flex--align-self-end {
			align-self: flex-end;
		}
.flex--align-self-center {
			align-self: center;
		}
.flex--align-self-stretch {
			align-self: stretch;
		}
.flex--align-self-baseline {
			align-self: baseline;
		}
.flex--justify-self-start {
			justify-self: flex-start;
		}
.flex--justify-self-end {
			justify-self: flex-end;
		}
.flex--justify-self-center {
			justify-self: center;
		}
.flex--justify-self-stretch {
			justify-self: stretch;
		}
.flex--justify-self-baseline {
			justify-self: baseline;
		}
.flex--grow {
		flex-grow: 1;
	}
.flex--no-grow {
		flex-grow: 0;
	}
.flex--shrink {
		flex-shrink: 1;
	}
.flex--no-shrink {
		flex-shrink: 0;
	}
.box {
	max-width: var(--input-width);
	margin: 0 auto;
	box-shadow: 0 0 0 1px var(--color-blue);
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: var(--border-radius);
	padding: var(--spacing)
}
.box > *:first-child {
		margin-top: 0;
	}
.box > *:last-child {
		margin-bottom: 0;
	}
.video {
    margin: var(--spacing) 0;
    max-width: 100%;
    cursor: pointer;
}
.video--full-width {
        max-width: 100vw;
        max-height: 100vh;
        width: 100vw;
    }
.breakpoint-medium .video--full-width {
            max-width: calc(100vw - var(--navigation-width--static));
		}
.grid {
	display: grid;
	--grid-spacing: 0;
}
.breakpoint-medium .grid {
		--grid-spacing: var(--spacing);
	}
.grid--justify-self-end {
			justify-self: end;
		}
.grid--center-scroll {
		width: calc(100% + 2 * var(--grid-spacing));
		margin-left: calc(var(--grid-spacing) * -1);
		grid-template-areas: 'top' 'center' 'bottom';
		grid-template-rows: min-content minmax(0, 100%) min-content;
	}
.grid--center-scroll .grid__top {
			grid-area: top;
			padding: 0 var(--grid-spacing);
		}
.grid--center-scroll .grid__center {
			grid-area: center;
			/* overflow: hidden; */ /* removed to prevent issues with MS Edge in compensation shares component */
			padding: 0 var(--grid-spacing);
		}
.grid--center-scroll .grid__bottom {
			grid-area: bottom;
			position: sticky;
			bottom: var(--spacing);
			margin-top: var(--spacing);
			/* margin-bottom: var(--spacing); */
			margin-bottom: 0
		}
.grid--center-scroll .grid__bottom.moved-by-accordion {
				bottom: calc(var(--accordion-content-height, 0) + var(--spacing));
			}
.grid--center-scroll .grid__bottom.moved-by-accordion > *:last-child {
					margin-bottom: 0;
				}
.form-view {
	display: grid;
}
/* grid-template-rows: min-content min-content min-content min-content min-content min-content; */
.modal .form-view {
		--input-width: 100%;
	}
.modal .form-view > * {
			padding-left: var(--spacing);
			padding-right: var(--spacing);
		}
/* grid-template-areas: 'title' 'bodytext' 'fields' 'additional-fields' 'error' 'submit'; */
.form-view__form {
		display: contents;
	}
.modal .form-view__form > * {
				padding-left: var(--spacing);
				padding-right: var(--spacing);
			}
.form-view__title {
		/* grid-area: title; */
		display: flex;
		flex-direction: column;
		align-items: center;
	}
.modal .form-view__title {
			margin-top: 0;
			padding-left: var(--spacing);
			padding-right: var(--spacing);
		}
.form-view__bodytext {
		/* grid-area: bodytext; */
		margin-left:auto;
		margin-right:auto;
		display: flex;
		flex-direction: column;
		justify-self: center;
		max-width: var(--input-width);
		width: 100%;
	}
.form-view__fields {
		/* grid-area: fields; */
	}
.form-view__field {
		/* margin-left: 0; */
	}
.form-view__additional-fields {
		/* grid-area: additional-fields; */
		display: flex;
		flex-direction: column;
		justify-self: center;
		max-width: var(--input-width);
		width: 100%;
		/* align-items: center; */
		margin-bottom: var(--spacing);
	}
.form-view__error {
		/* grid-area: error; */
		justify-self: center;
	}
.form-view__submit {
		/* position: sticky; */
		margin-top: var(--spacing);
	}
/* grid-area: submit; */
.form--hide-submit-if-fields-empty .form-view__submit {
			display: none;
		}
.form[class*='form-view__form--error'].form--hide-submit-if-fields-empty .form-view__submit {
				display: block;
			}
.form-view__submit--error-all-fields-empty {
			opacity: 0;
		}
.modal .form-view__submit {
			width: 100%;
			margin-bottom: 0;
			bottom: 0;
		}
.form-view__success-icon {
		margin: var(--spacing) auto;
		display: block;
		width: 30em;
		max-width: 50vw;
		max-height: 50vh;
		--checkbox-stroke-width: 6px;
		--checkbox-stroke-color: var(--color-cyan);
		--checkbox-delay: 500ms;
		--checkbox-duration: 3s;
		--checkbox-timing-function: var(--ease-in-out-quad);
		/* TODO: use dynamically calculated path lenght here */
		--checkbox-path-length: 10000;
		--checkmark-visibility-start: 0;
	}
.form-view__success-icon circle,
		.form-view__success-icon polyline {
			stroke-dashoffset: var(--checkbox-path-length);
			animation-name: checkmarkAnimation;
			animation-delay: var(--checkbox-delay);
			animation-duration: var(--checkbox-duration);
			animation-timing-function: var(--checkbox-timing-function);
			animation-fill-mode: forwards;
			stroke-dasharray: var(--checkbox-path-length);
		}
.form-view__success-icon polyline {
			animation-delay: calc(var(--checkbox-delay) * 2.5);
			stroke-linejoin: round;
			stroke-linecap: round;
		}
@keyframes checkmarkAnimation {
	from {
		stroke-dashoffset: var(--checkbox-path-length);
	}

	to {
		stroke-dashoffset: 0;
	}
}
.hidden {
	display: none !important;
}
.visually-hidden {
	--visually-hidden-opacity: 0;
	opacity: var(--visually-hidden-opacity);
	transition: opacity 0.25s var(--ease-in-out-quad);
}
.visually-hidden--half-opaque {
		--visually-hidden-opacity: 0.25;
	}
.visually-hidden--false {
		--visually-hidden-opacity: 1;
	}
.drop-shadow--bottom-3-steps {
		filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1))
			drop-shadow(0 4px 4px rgba(0, 0, 0, 0.1));
	}
.no-auto-hyphens {
	hyphens: manual;
}
.narrow-width {
	--content-max-width: var(--content-max-width--narrow);
	max-width: var(--content-max-width);
}
.center-view {
	max-width: 100%;
}
.breakpoint-medium .center-view {
		max-width: var(--content-max-width);
		margin-left: auto;
		margin-right: auto;
	}
.full-width {
	width: calc(100% + var(--spacing) * 2);
	margin-left: calc(var(--spacing) * -1);
}
.cursor--pointer {
		cursor: pointer;
	}
.pointer-events--none {
		pointer-events: none;
	}
.pointer-events--force-none {
		pointer-events: none !important
	}
.pointer-events--force-none * {
			pointer-events: none !important;
		}
/* css attribute is set in basic.css */
.user-select {
	--user-select: auto;
}
.user-select--text {
		--user-select: text;
	}
.user-select--none {
		--user-select: none;
	}
.user-select--none-force * {
				--user-select: none;
			}
.user-select--all {
		--user-select: all;
	}
.opacity--100 {
		opacity: 1;
	}
.opacity--50 {
		opacity: 0.5;
	}
.opacity--0 {
		opacity: 0;
	}
.font-weight--medium {
		font-family: var(--font-medium);
	}
/* html tag before class to set specificity higher than default overwrites in default.css */
html .margin {
		margin: var(--margin);
	}
html .margin--auto {
			margin-left: auto;
			margin-right: auto;
		}
html .margin--vertical {
			margin-top: var(--margin);
			margin-bottom: var(--margin);
		}
html .margin--left {
			margin-left: var(--margin);
		}
html .margin--right {
			margin-right: var(--margin);
		}
html .margin--top {
			margin-top: var(--margin);
		}
html .margin--bottom {
			margin-bottom: var(--margin);
		}
html .margin--horizontal {
			margin-left: var(--margin);
			margin-right: var(--margin);
		}
html .margin--small {
			--margin: var(--spacing--small);
		}
html .margin--small-negative {
				--margin: calc(var(--spacing--small) * -1);
			}
html .margin--default {
			--margin: var(--spacing);
		}
html .margin--large {
			--margin: calc(var(--spacing) * 2);
		}
html .margin--large-negative {
				--margin: calc(var(--spacing--small) * -2);
			}
html .margin--extra-large {
			--margin: calc(var(--spacing) * 3);
		}
html .margin--extra-large-negative {
				--margin: calc(var(--spacing--small) * -3);
			}
html .no-margin {
		margin: 0 !important;
	}
html .no-margin > *:first-child {
			margin: 0 !important;
		}
html .no-margin--top {
			margin-top: 0 !important;
		}
html .no-margin--top > *:first-child {
				margin-top: 0 !important;
			}
html .no-margin--bottom {
			margin-bottom: 0 !important;
		}
html .no-margin--bottom > *:first-child {
				margin-bottom: 0 !important;
			}
html .no-margin--left {
			margin-left: 0 !important;
		}
html .no-margin--left > *:first-child {
				margin-left: 0 !important;
			}
html .no-margin--right {
			margin-right: 0 !important;
		}
html .no-margin--right > *:first-child {
				margin-right: 0 !important;
			}
html .padding {
		padding: var(--padding);
	}
html .padding--vertical {
			padding-top: var(--padding);
			padding-bottom: var(--padding);
		}
html .padding--right {
			padding-right: var(--padding);
		}
html .padding--top {
			padding-top: var(--padding);
		}
html .padding--bottom {
			padding-bottom: var(--padding);
		}
html .padding--horizontal {
			padding-left: var(--padding);
			padding-right: var(--padding);
		}
html .padding--small {
			--padding: var(--spacing--small);
		}
html .padding--small-negative {
				--padding: calc(var(--spacing--small) * -1);
			}
html .padding--large {
			--padding: calc(var(--spacing) * 2);
		}
html .padding--large-negative {
				--padding: calc(var(--spacing--small) * -2);
			}
html .padding--extra-large {
			--padding: calc(var(--spacing) * 3);
		}
html .padding--extra-large-negative {
				--padding: calc(var(--spacing--small) * -3);
			}
html .no-padding {
		padding: 0 !important;
	}
html .no-padding > *:first-child {
			padding: 0 !important;
		}
html .no-padding--top {
			padding-top: 0 !important;
		}
html .no-padding--top > *:first-child {
				padding-top: 0 !important;
			}
html .no-padding--bottom {
			padding-bottom: 0 !important;
		}
html .no-padding--bottom > *:first-child {
				padding-bottom: 0 !important;
			}
html .no-padding--left {
			padding-left: 0 !important;
		}
html .no-padding--left > *:first-child {
				padding-left: 0 !important;
			}
html .no-padding--right {
			padding-right: 0 !important;
		}
html .no-padding--right > *:first-child {
				padding-right: 0 !important;
			}
.text--bold-spaced {
		font-size: var(--font-size-xs);
		font-weight: var(--font-system-bold);
		font-family: var(--font-system);
		text-transform: uppercase;
		letter-spacing: 0.28em;
	}
.text-align--left {
			text-align: left;
		}
.text-align--center {
			text-align: center;
		}
.text-align--right {
			text-align: right;
		}
.text-color-blue {
		color: var(--color-blue);
	}
/* .background-color--$(color) {
		background-color: var(--color-$(color));
	} */
.text-color-cyan {
		color: var(--color-cyan);
	}
/* .background-color--$(color) {
		background-color: var(--color-$(color));
	} */
.text-color-red {
		color: var(--color-red);
	}
/* .background-color--$(color) {
		background-color: var(--color-$(color));
	} */
.text-color-white {
		color: var(--color-white);
	}
/* .background-color--$(color) {
		background-color: var(--color-$(color));
	} */
.font-size-xxl {
		font-size: var(--font-size-xxl);
	}
&--black {
		color: var(--color-black);
	}
&--cyan {
		color: var(--color-blue);
	}
.font-size-xl {
		font-size: var(--font-size-xl);
	}
&--black {
		color: var(--color-black);
	}
&--cyan {
		color: var(--color-blue);
	}
.font-size-l {
		font-size: var(--font-size-l);
	}
&--black {
		color: var(--color-black);
	}
&--cyan {
		color: var(--color-blue);
	}
.font-size-m {
		font-size: var(--font-size-m);
	}
&--black {
		color: var(--color-black);
	}
&--cyan {
		color: var(--color-blue);
	}
.font-size-default {
		font-size: var(--font-size-default);
	}
&--black {
		color: var(--color-black);
	}
&--cyan {
		color: var(--color-blue);
	}
.font-size-s {
		font-size: var(--font-size-s);
	}
&--black {
		color: var(--color-black);
	}
&--cyan {
		color: var(--color-blue);
	}
.font-size-xs {
		font-size: var(--font-size-xs);
	}
&--black {
		color: var(--color-black);
	}
&--cyan {
		color: var(--color-blue);
	}
